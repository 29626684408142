// React:
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
// AntD:
import { Layout, Avatar, Image, Tooltip, Menu, Tabs, Spin, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { ClusterOutlined, MessageOutlined, MessageFilled, FileTextOutlined, FileTextFilled, DatabaseOutlined, DatabaseFilled, SearchOutlined, UserOutlined, PushpinFilled, OrderedListOutlined, TableOutlined, MenuOutlined, PlusSquareOutlined, FolderOutlined, QuestionCircleOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { getAuth } from "firebase/auth";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import ReactHotkeys from "react-hot-keys";
import { PinnedJSONFormsContext, SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { ChatEditorRefFunctions } from "../../../ServerConnection/LLMServer/SendChatToServerHook";
import { ChatLogContext } from "../../../DecisionGraph/ChatEditor/UI/ChatLog";
import { getQuery, useQuery } from "../../../DecisionGraph/Utilities/URLQueryParams";
import SaveableDraftPluginsNoteEditor from "../DraftJSEditor/DraftJSPluginsEditor/SaveableDraftPluginsNoteEditor";
import NoteDataEditor from "../NoteInformationComponents";
import { BattleNoteTypeEditor } from "../../../Extensions/TTRPG/Battle/BattleNoteTypeEditor";
import { useSoundTrackInDrawer } from "../../../DecisionGraph/Utilities/Sound/MusicAndSpeechDrawer";
import TagTree from "../../NotesTree/TagTree";
import SearchSidebar from "../../../DecisionGraph/Sidebars/SearchSidebar";
import { ReplicaVoiceSidebar } from "../../../DecisionGraph/Utilities/Sound/ReplicaVoiceSidebarAndModal";
import ChatEditorWithNotePreprocessing from "../../../DecisionGraph/ChatEditor/UI/ChatEditorV2OnNotePage";
import { DraftJSOutline } from "../DraftJSEditor/DraftJSPluginsEditor/DraftJSOutlineSidebar";
import { RelatedNotesLargePanel } from "../NoteInformationComponents/RelatedNotesLargePanel";
import { NoteHierarchyContext } from "../NoteInformationComponents/NoteHierarchyProvider";
import { useNewNoteAction } from "../../../DecisionGraph/ActionComponents/NewNoteAction";
import { HELP_URL } from "../../../DecisionGraph/ActionComponents/OpenHelpAction";
import { SIDER_FONT_COLOR, siderItemPaddingLeft, SiderMenuButton } from "./SiderMenuButton";
import { useDeleteSelectedNotesAction } from "../../../DecisionGraph/ActionComponents/DeleteNoteAction";
import { useBulkUploadMenuItem } from "../../../DecisionGraph/ActionComponents/BulkUpload";
import { useIsSmallScreen } from "./IsSmallScreen";


// Extracted Ant Properties (These must be below the imports)
const { Header, Sider, Content } = Layout;

const HEADER_ABOVE_TABS=false;
const INCLUDE_RELATED_SIDEBAR=false;

export const SELECTED_TAB_PARAMETER="selectedTab";
export const SELECTED_TAB_IS_NOTE="Note";
export const SELECTED_TAB_IS_DATA="Object";
export const SELECTED_TAB_IS_CHAT="Chat";
export const SELECTED_TAB_IS_RELATED="Related";

export const SIDEBAR_DISPLAYED_PARAMETER="sidebarDisplayed";
export const SIDEBAR_VOICES="Voices";

// Button menu keys
const MENU_KEY_NEW_NOTE = "newNote";
const MENU_KEY_DELETE_NOTE = "deleteNote";

// Sidebar menu keys
const MENU_KEY_LIST_OF_NOTES = "listOfNotes";
const MENU_KEY_SEARCH = "search";
const MENU_KEY_VOICES = "listOfVoices";
const MENU_KEY_OUTLINE = "outline";

function useChatTab(chatRefV2:React.MutableRefObject<ChatEditorRefFunctions>) {
  const pinnedJsonFormsContext = useContext(PinnedJSONFormsContext);
  const {selectedChatMessages} = useContext(ChatLogContext);

  let chatTabTitle = "Chat";
  if (pinnedJsonFormsContext.note) {
    chatTabTitle = "Chat";
  }

  return {
    label: <span>{chatRefV2.current.isWaitingForChat?<Spin size="small"/>:(selectedChatMessages?.length>0?<MessageFilled />:<MessageOutlined/>)}&nbsp;{chatTabTitle}</span>,
    key: SELECTED_TAB_IS_CHAT,
    children: <ChatEditorWithNotePreprocessing chatEditorRef={chatRefV2}/>,
  };
}
function useRelatedTab() {
  const {isLoaded} = useContext(NoteHierarchyContext);
  return {
    label: <span>{isLoaded?<TableOutlined />:<Spin size="small"/>}<> Related</></span>,
    key: SELECTED_TAB_IS_RELATED,
    children: <RelatedNotesLargePanel/>,
  };
}


export default function NotePage() {
  /***************************************
   * Hooks
   */
  const chatRef = useRef();
  const chatRefV2 = useRef<ChatEditorRefFunctions>({} as ChatEditorRefFunctions);

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
  const doc_id = selectedJsonFormsContext.note?.id;
  const noteType = selectedJsonFormsContext.note?.type;
  const pinnedJsonFormsContext = useContext(PinnedJSONFormsContext);

  const isSmallScreen = useIsSmallScreen();
  
  // Visibility of panels from query:
  const sidebarDisplayed = query.get(SIDEBAR_DISPLAYED_PARAMETER);
  const listOfNotesVisible = !sidebarDisplayed || sidebarDisplayed==="Notes";
  const searchVisible = sidebarDisplayed==="Search";
  const listOfVoicesVisible = sidebarDisplayed===SIDEBAR_VOICES;
  function setListOfNotesVisible() {
    const freshQuery = getQuery();
    freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,"Notes");
    navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
  }
  function setSearchVisible() {
    const freshQuery = getQuery();
    freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,"Search");
    navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
    setTimeout(()=>{
      searchRef?.current?.focus();
    },200);
  }
  function setListOfVoicesVisible() {
    const freshQuery = getQuery();
    freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,SIDEBAR_VOICES);
    navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
  }
  function setNoSidebarVisible() {
    const freshQuery = getQuery();
    freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,"None");
    navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
  }
  function setOutlineVisible() {
    const freshQuery = getQuery();
    freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,MENU_KEY_OUTLINE);
    navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
  }

  const siderCollapsedFromQueryStr = query.get("siderCollapsed");
  const isSiderCollapsed = siderCollapsedFromQueryStr===null?false:siderCollapsedFromQueryStr==="true";
  function setSiderCollapsed(value:boolean) {
    const freshQuery = getQuery();
    freshQuery.set("siderCollapsed",value.toString());
    navigate(location.pathname+"?"+freshQuery.toString(),{replace:true}); // changing sidebar visibility does not count as affecting the back button.
  }
  const selectedTabFromQuery = query.get(SELECTED_TAB_PARAMETER);
  let selectedTab = selectedTabFromQuery || "";
  function setSelectedTab(value:string) {
    const freshQuery = getQuery();
    freshQuery.set(SELECTED_TAB_PARAMETER,value);
    navigate(location.pathname+"?"+freshQuery.toString(),{replace:true}); // changing tabs does not count as affecting the back button.
  }
  
  const searchRef = useRef<HTMLInputElement>();
  function focusSearch() {
    // Set focus to the search box:
    // console.log("NotePage.focusSearch() called. searchVisible=",searchVisible);
    if (searchVisible) {
      // Just focus it:
      setTimeout(()=>{
        searchRef?.current?.focus();
      },200);
      // setNoSidebarVisible();
      return;
      // console.error("This is not implemented... We don't know how to focus the search bar if it's already visible.");
    }
    setSearchVisible();
  }

  const isOutlinableContent = selectedTab===SELECTED_TAB_IS_NOTE;
  const outlineVisible = sidebarDisplayed===MENU_KEY_OUTLINE; // when the tab is not the note, we can't show the outline. But, auto-hiding it makes the screen jitter. So we won't auto-hide it after the user has clicked, it'll just be blank. 
  
  const siderTopMenuItems = [];

  const {bulkUploadMenuItem, bulkUploadComponent} = useBulkUploadMenuItem();
  {
    const noteMenuItems = [];
    const onNewNoteClicked = useNewNoteAction(doc_id);
    noteMenuItems.push({key:MENU_KEY_NEW_NOTE,label:"New Note",icon:<PlusSquareOutlined />, onClick:()=>onNewNoteClicked()});
    noteMenuItems.push({key:MENU_KEY_LIST_OF_NOTES,label:"List",icon:<FolderOutlined />});
    noteMenuItems.push({key:MENU_KEY_SEARCH,label:"Search",icon:<SearchOutlined />});
    const deleteSelectedNotesAction = useDeleteSelectedNotesAction();
    noteMenuItems.push(bulkUploadMenuItem);

    // TODO pick a standard and be consistent: decide whether to hide irrelevant menu items or to disable them
    if (doc_id) {
      noteMenuItems.push({key:MENU_KEY_OUTLINE,label:"Outline",icon:<OrderedListOutlined />, disabled:!isOutlinableContent})
    }
    // Delete can operate on one or manay notes:
    {
      const selectedNoteIDs:string[] = query.getAll("notes");
      const oneOrManyNotesAreSelected = doc_id || selectedNoteIDs.length>0;
      if (oneOrManyNotesAreSelected) {
        noteMenuItems.push({key:MENU_KEY_DELETE_NOTE,label:"Delete",icon:<DeleteOutlined />, onClick:()=>deleteSelectedNotesAction()});
      }
    }
    siderTopMenuItems.push({key:"notesSubMenu",label:"Notes",icon:<FileTextOutlined />, children:noteMenuItems});
  }
  const {soundtrackMenuItem, soundtrackComponent} = useSoundTrackInDrawer()
  siderTopMenuItems.push(soundtrackMenuItem);

  if (INCLUDE_RELATED_SIDEBAR)
    siderTopMenuItems.push({key:"showRelated",label:pinnedJsonFormsContext.hasNote?(pinnedJsonFormsContext.note?pinnedJsonFormsContext.note.doc_name:"Pinned"):"Related",icon:pinnedJsonFormsContext.hasNote?<PushpinFilled/>:<ClusterOutlined />, disabled:false});
  siderTopMenuItems.push({key:MENU_KEY_VOICES,label:"Voices",icon:<UserOutlined />, disabled:false});

  if (isSmallScreen) {
    // TODO this is a bit of a hack, we need it only because we can't always see the hide button -- e.g. when sidebar is showing
    // TODO we want to move the "Music" into the menus instead of as a wierd component. Right now it's acctually below "Close menu"
    siderTopMenuItems.push({key:"hideSidebar",label:"Close",icon:<CloseOutlined />, disabled:false, onClick:()=>setSiderCollapsed(true)});
  }

  let siderTopMenuItemSelectedKey = "";
  if (listOfNotesVisible)
    siderTopMenuItemSelectedKey=MENU_KEY_LIST_OF_NOTES;
  else if (searchVisible)
    siderTopMenuItemSelectedKey=MENU_KEY_SEARCH;
  else if (listOfVoicesVisible)
    siderTopMenuItemSelectedKey=MENU_KEY_VOICES;
  else if (outlineVisible)
    siderTopMenuItemSelectedKey=MENU_KEY_OUTLINE;

  function onSiderMenuItemClick({key}:{key:string}) {
    // console.log('click ', e);
    // On a mobile device, we also hide the sider menu as soon as the user makes any selection.
    if (isSmallScreen)
      setSiderCollapsed(true);

    // If the user clicks a sidebar that's already visible, we'll hide it.
    if (key===MENU_KEY_LIST_OF_NOTES) {
      if (listOfNotesVisible)
        setNoSidebarVisible();
      else
        setListOfNotesVisible();
    } else if (key===MENU_KEY_SEARCH) {
      if (searchVisible)
        setNoSidebarVisible();
      else
        setSearchVisible();
    } else if (key===MENU_KEY_VOICES) {
      if (listOfVoicesVisible)
        setNoSidebarVisible();
      else
        setListOfVoicesVisible();
    } else if (key===MENU_KEY_OUTLINE) {
      if (outlineVisible)
        setNoSidebarVisible();
      else
        setOutlineVisible();
    }
  };

  /**************************************
   * 
   * Dynamic Tabs based on selection
   */
  const tabItems = [];
  if (doc_id) {
    tabItems.push({
      label: <span>{(selectedJsonFormsContext?.note?.doc_data?.blocks?.length>2 || (selectedJsonFormsContext?.note?.doc_data?.blocks?.length===2 && selectedJsonFormsContext?.note?.doc_data?.blocks[1].text.length>0))?<FileTextFilled/>:<FileTextOutlined/>}Note</span>,
      key: SELECTED_TAB_IS_NOTE,
      children: <SaveableDraftPluginsNoteEditor focusSearch={focusSearch} doc_id={doc_id}/>,
    });
    tabItems.push({
      label: <span>{selectedJsonFormsContext?.jsonFormsObject?.version>=0?<DatabaseFilled />:<DatabaseOutlined/>}Data</span>,
      key: SELECTED_TAB_IS_DATA,
      // TODO this has a bunch of tabs in it... We should really collapse the tabs into the upper level here, no need for two rows of tabs!
      // That will require quite a bit of refactoring of NoteDataEditor since those tabs are currently fairly deep down in GenericObjectEditor.
      children: <NoteDataEditor/>,
    });
  }
  tabItems.push(useChatTab(chatRefV2));
  tabItems.push(useRelatedTab());
  if (noteType==="Battle") {
    tabItems.push({
      label: <span>Battle</span>,
      key: 'Battle',
      children: <BattleNoteTypeEditor />,
    });
    if (!selectedTab)
      selectedTab="Battle";
  } else {
    // Not a Battle type
    if (!selectedTab)
      selectedTab=SELECTED_TAB_IS_NOTE;
    else if (selectedTab==="Battle")
      selectedTab=SELECTED_TAB_IS_CHAT;
  }

  function onShortcutKeyDown(keyName:string, e:any, handle:any) {
    e.preventDefault();
    e.stopPropagation();
    if (keyName==="control+e" || keyName==="command+e" || keyName==="control+shift+f" || keyName==="command+shift+f" || keyName==="alt+e" || keyName==="alt+f") {
      if (searchVisible) {
        setTimeout(()=>{
          searchRef?.current?.focus();
        },200);
        return;
      }
      console.log("NotePage.onShortcutKeyDown - Search hotkey pressed: setting search to visible");
      setSearchVisible();
    } else if (keyName==="control+s" || keyName==="command+s") {
      // Control-s does not need to be handled: users may have a tendency to press control-s, but it's already saved, and we don't want to switch to the HTML save dialog which is very intrusive.
    } else if (keyName==="control+/" || keyName==="command+/") {
      // Switch to the chat tab:
      if (selectedTab!==SELECTED_TAB_IS_CHAT)
        setSelectedTab(SELECTED_TAB_IS_CHAT);
      // Focus the chat input:
      setTimeout(()=>{
        //@ts-ignore
        chatRef?.current?.focusChatInput();
      },200);
    } else if (keyName==="control+o" || keyName==="command+o" || keyName==="alt+o")
      setListOfNotesVisible(); // Switch to the notes list sidebar
    else if (keyName==="control+shift+o" || keyName==="command+shift+o")
      setOutlineVisible(); // Switch to the outline sidebar
    else if (keyName.includes("alt+1"))
      setSelectedTab(SELECTED_TAB_IS_NOTE);
    else if (keyName.includes("alt+2"))
      setSelectedTab(SELECTED_TAB_IS_DATA);
    else if (keyName.includes("alt+3"))
      setSelectedTab(SELECTED_TAB_IS_CHAT);
    else if (keyName.includes("alt+4"))
      setSelectedTab(SELECTED_TAB_IS_RELATED);
    else if (keyName.includes("alt+5") && noteType==="Battle")
      setSelectedTab("Battle");
  }

  const auth = getAuth();
  const photoURL = auth.currentUser?.photoURL;

  useEffect(() => {
    if (selectedJsonFormsContext.note?.doc_name) 
        document.title = selectedJsonFormsContext.note?.doc_name+' - AI Got This';
  }, [selectedJsonFormsContext.note?.doc_name]);

  
  return <ReactHotkeys keyName="control+e,command+e,alt+e,alt+f,control+shift+f,command+shift+f,control+s,command+s,control+/,command+/,control+o,command+o,alt+o,alt+1,control+alt+1,command+alt+1,alt+2,control+alt+2,alt+3,control+alt+3,alt+4,control+alt+4,alt+5,control+alt+5,control+shift+o,command+shift+o" onKeyDown={onShortcutKeyDown} filter={(_event) => {return true;}}>
    <div className={HEADER_ABOVE_TABS?"HEADER_ABOVE_TABS":"HEADER_IN_TABS"}>
      {/*The following are modals, mouseovers, drawers, etc, that can go anywhere*/}
      {bulkUploadComponent}
      {soundtrackComponent}
    <Layout>
      <Sider collapsible={true} className="noprint"
        /* On narrow layout mobile screens, we'd like to make the collapsedWidth 0 to take up even less horizontal space.
        This triggers on mobile & tablet only, when in portrait layout.*/
        collapsedWidth={isSmallScreen?0:undefined}
        collapsed={isSiderCollapsed}
        // Once we put the zero width trigger in a better place, we'll turn this on to hide the trigger.
        zeroWidthTriggerStyle={isSmallScreen?{display:"none"}:{}} // This hides the trigger button that would otherwise be visible when the sidebar is collapsed.
        onCollapse={(collapsed:boolean, type) => {
          setSiderCollapsed(collapsed);
        }}>
        {!isSmallScreen && !isSiderCollapsed && <h1 style={{color:SIDER_FONT_COLOR,paddingLeft:siderItemPaddingLeft(isSiderCollapsed)}}>AI Got This</h1>}
        <Menu theme="dark" mode="inline" items={siderTopMenuItems} onClick={onSiderMenuItemClick} selectedKeys={[siderTopMenuItemSelectedKey]}/>
        {/* This flexible space makes anything below show at the bottom: */}
        <div
            key="bottom-spacer"
            style={{ marginTop: 'auto', display: 'hidden' }}
          />
        {/* TODO convert the following to a menu item to improve consistency */}
        <SiderMenuButton labelIcon={<QuestionCircleOutlined/>} labelText="Help" onClick={()=>window.open(HELP_URL,"_blank")} siderCollapsed={isSiderCollapsed}/>
        <Tooltip title={auth.currentUser?.email+"  "+auth.currentUser?.uid} key="tooltip">
          <div style={{width:"100%", alignItems:"center", color:"gray",paddingLeft:isSiderCollapsed?"20px":"24px",paddingRight:isSiderCollapsed?"0px":"24px",display:"flex"}}
  >
            <Avatar
              src={photoURL && <Image src={photoURL}/>}
              key="avatarInHeader"
            />
            {!isSiderCollapsed && <span style={{paddingLeft:"24px",color:"gray", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
              {auth.currentUser?.email}
              </span>}
          </div>
        </Tooltip>
      </Sider>
      <Content>
        <div id="JustAroundAllotment">
          <Allotment snap={true} vertical={false} defaultSizes={[200,600]/*isBulkEditor?[200,600]:[200,600,600]*/} 
            onVisibleChange={(index:number, value:boolean) => {
              if (index === 0) {
                // console.log("setListOfNotesVisible to "+value)
                setNoSidebarVisible();
              } else {
                console.error("Unexpected index in onVisibleChange: "+index);
              }
            }}>

            <Allotment.Pane minSize={150} preferredSize={200} visible={listOfNotesVisible || searchVisible || listOfVoicesVisible || outlineVisible}>
              {listOfNotesVisible && <TagTree/>}
              {searchVisible && <SearchSidebar searchRef={searchRef}/>}
              {listOfVoicesVisible && <ReplicaVoiceSidebar/>}
              {outlineVisible &&
                (selectedJsonFormsContext.noteEditorRef.current?.editorState && selectedJsonFormsContext.noteEditorRef.current?.setEditorState)?<DraftJSOutline editorState={selectedJsonFormsContext.noteEditorRef.current?.editorState} setEditorState={selectedJsonFormsContext.noteEditorRef.current?.setEditorState}/>:""}
            </Allotment.Pane>

            <Allotment.Pane minSize={300} preferredSize={1000}>
                  <Tabs destroyInactiveTabPane={true} activeKey={selectedTab} onChange={(key)=>setSelectedTab(key)}
                    key={noteType}
                    type="card"
                    items={tabItems}
                    tabBarExtraContent={
                      !HEADER_ABOVE_TABS && 
                        {
                          left: isSmallScreen?
                            <Button icon={<MenuOutlined/>} type="text" onClick={()=>setSiderCollapsed(!isSiderCollapsed)}/>
                            :<></>,
                        }}
                  />
              </Allotment.Pane>
          </Allotment>
        </div>
      </Content>
    </Layout>
  </div>
  </ReactHotkeys>;
}
