import OpenAI from "openai";
import {  ChatAPICall, ChatAPICallReturnType, ChatAPIOptions, ChatStreamErrorCallback, DEFAULT_CHAT_API_OPTIONS, StreamingCallback, cleanChatMessagesBeforeSendToServer } from "./GenericChatServer";
import { ChatCompletionCreateParamsNonStreaming } from "openai/resources";
import { ChatCompletionCreateParamsBase, ChatCompletionCreateParamsStreaming, ChatCompletionMessageParam, ChatCompletionMessageToolCall } from "openai/resources/chat/completions";

const DEBUG = false;

const OPEN_AI_MODEL = "gpt-4o";

async function callChatAPIWithRetries_NonStreaming(sendPayload:ChatCompletionCreateParamsNonStreaming, streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void, startTime?:Date,numTriesLeft=1):Promise<ChatAPICallReturnType>{
    if (!startTime) {
        startTime = new Date();
    }
    const openai_api_key = localStorage.getItem("OPENAI_API_KEY");
    if (!openai_api_key) {
        console.error("[callChatAPIWithRetries_NonStreaming] No OpenAI API key found in local storage.");
        debugger;
        return {success:false, error:"No OpenAI API key found in local storage."};
    }
    const openai = new OpenAI({        
        apiKey: openai_api_key as string,
        dangerouslyAllowBrowser: true
    });
    function getElapsedSeconds() {
        if (!startTime) {
            console.error("No start time in getElapsedSeconds");
            debugger;
            return 0;
        }
        return Math.round((new Date().getTime()-startTime.getTime())/1000);
    }
    async function failedMayRetry(failureReason:string):Promise<ChatAPICallReturnType> {
        if (numTriesLeft>0) {
            console.error("[callChatAPIWithRetries_NonStreaming] OpenAI failed after "+getElapsedSeconds()+"s, trying again. Failure reason: ",failureReason);
            return await callChatAPIWithRetries_NonStreaming(sendPayload, streamingCallback, errorCallback, callFunction, startTime, numTriesLeft-1);
        }
        // It's failed too many times.
        errorCallback && errorCallback(failureReason);
        if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]> failed after "+getElapsedSeconds()+"s");
        return {success:false, error:failureReason};
    }
    try {
        const chatAnswer = await openai.chat.completions.create(sendPayload);
        if (!chatAnswer || !chatAnswer.usage || !(chatAnswer.usage.completion_tokens>0) || chatAnswer.choices.length===0) {
            // failed.
            return await failedMayRetry("OpenAI returned "+JSON.stringify(chatAnswer));
        }
        const content = chatAnswer.choices[0].message.content || "";
        if (callFunction && chatAnswer.choices[0].message.tool_calls) {
            callFunction(chatAnswer.choices[0].message.tool_calls.map((toolCall)=>toolCall.function));
        }
        if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI succeeded after "+getElapsedSeconds()+"s, returning: ",content,chatAnswer);
        if (streamingCallback) {
            if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI succeeded after "+getElapsedSeconds()+"s. Sending to the stream: ",content);
            streamingCallback(content,content, true);
        } else {
            if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI succeeded after "+getElapsedSeconds()+"s. There's no stream, so we're simply returning the result: ",content);
        }
        return {success:true, message:content};
    } catch (e) {
        console.error("[callChatAPIWithRetriesNonStreaming] Error: ",e);
        return await failedMayRetry("Failed to get answer from openai due to exception: "+e);
    }
}

async function callChatAPIWithRetries_Streaming(sendPayload:ChatCompletionCreateParamsStreaming, streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void, startTime?:Date,numTriesLeft=1):Promise<ChatAPICallReturnType>{
    if (!startTime) {
        startTime = new Date();
    }
    const openai_api_key = localStorage.getItem("OPENAI_API_KEY");
    if (!openai_api_key) {
        console.error("[callChatAPIWithRetries_NonStreaming] No OpenAI API key found in local storage.");
        debugger;
        return {success:false, error:"No OpenAI API key found in local storage."};
    }
    const openai = new OpenAI({        
        apiKey: openai_api_key as string,
        dangerouslyAllowBrowser: true
    });
    function getElapsedSeconds() {
        if (!startTime) {
            console.error("No start time in getElapsedSeconds");
            debugger;
            return 0;
        }
        return Math.round((new Date().getTime()-startTime.getTime())/1000);
    }
    async function failedMayRetry(failureReason:string):Promise<ChatAPICallReturnType> {
        if (numTriesLeft>0) {
            console.error("[callChatAPIWithRetries_NonStreaming] OpenAI failed after "+getElapsedSeconds()+"s, trying again. Failure reason: ",failureReason);
            return await callChatAPIWithRetries_Streaming(sendPayload, streamingCallback, errorCallback, callFunction, startTime, numTriesLeft-1);
        }
        // It's failed too many times.
        errorCallback && errorCallback(failureReason);
        if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]> failed after "+getElapsedSeconds()+"s");
        return {success:false, error:failureReason};
    }
    try {
        const stream = await openai.chat.completions.create(sendPayload);
        let fullContent = "";
        let fullFunctionCalls = [] as ChatCompletionMessageToolCall.Function[];
        for await (const chunk of stream) {
            const delta = chunk.choices[0]?.delta;
            if (!delta) {
                continue;
            }
            if (delta.tool_calls && delta.tool_calls.length>0) {
                // Each of these chunks can be added to the rest of the content, concatenated by property.
                for (let i=0; i<delta.tool_calls.length; i++) {
                    let toolCallDelta = delta.tool_calls[i].function as {[key:string]:any};
                    let fullToolCall = fullFunctionCalls[i];
                    if (!fullToolCall) {
                        fullToolCall={} as ChatCompletionMessageToolCall.Function;
                    }
                    for (const key in toolCallDelta) {
                        //@ts-ignore
                        const currentValue = fullToolCall[key] || "";
                        // @ts-ignore
                        fullToolCall[key] = currentValue+toolCallDelta[key];
                    }
    
                    fullFunctionCalls[i] = fullToolCall;
                }
                // const tool_call0 = delta.tool_calls[0].function as {[key:string]:any};
                // for (const key in tool_call0) {
                //     const currentValue = fullToolCall[key] || "";
                //     fullToolCall[key] = currentValue+tool_call0[key];
                // }
                // console.log("[callChatAPIWithRetries_Streaming] Got tool call: ",fullToolCalls);
            }
            const contentChunk = delta.content || "";
            fullContent += contentChunk;
            if (streamingCallback) {
                // if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI got another reply at "+getElapsedSeconds()+"s. Adding to the stream: ",contentChunk);
                streamingCallback(fullContent,contentChunk, false);
            } else {
                // if (DEBUG) console.log("[callChatAPIWithRetries_NonStreaming]>OpenAI succeeded after "+getElapsedSeconds()+"s. There's no stream, so we're simply returning the result: ",fullContent);
            }
        }
        callFunction?.(fullFunctionCalls);
        streamingCallback && streamingCallback(fullContent,"", true);
        return {success:true, message:fullContent};
    } catch (e) {
        console.error("[callChatAPIWithRetriesNonStreaming] Error: ",e);
        return await failedMayRetry("Failed to get answer from openai due to exception: "+e);
    }
}

function getPayloadBaseFrom(messages:ChatCompletionMessageParam[], chatAPIOptions:ChatAPIOptions):ChatCompletionCreateParamsBase {
    const {abortController, ...chatAPIOptionsRest} = chatAPIOptions;
    const messagesClean = cleanChatMessagesBeforeSendToServer(messages);
    const payloadBase = {
        // First, the defaults, which can be overriden by anythning:
        ...DEFAULT_CHAT_API_OPTIONS,

        // Then, our parameters. These can still be overridden by the caller.
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        // Then, we allow lots of overrides incoming:
        ...chatAPIOptionsRest,

        // And we always use these:
        messages: messagesClean,
        model: OPEN_AI_MODEL,
    } as ChatCompletionCreateParamsBase;
    if (!payloadBase.temperature || payloadBase.temperature<=0 || payloadBase.temperature>=1) {
        throw new Error("[callOpenAIDirect]>Invalid temperature: "+payloadBase.temperature);
    }
    return payloadBase;
}

export const callOpenAIDirect_NoStreaming:ChatAPICall = async function (messages:ChatCompletionMessageParam[], streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, chatAPIOptions:ChatAPIOptions=DEFAULT_CHAT_API_OPTIONS, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void):Promise<ChatAPICallReturnType> {
    // if (DEBUG) console.log("[callOpenAIDirect]>Starting the call. messages: ",messages, "temperature: ",temperature);
    const nonStreamingPayload = {
        ...getPayloadBaseFrom(messages, chatAPIOptions),
        stream: false,
    } as ChatCompletionCreateParamsNonStreaming;

    return await callChatAPIWithRetries_NonStreaming(nonStreamingPayload, streamingCallback, errorCallback, callFunction);
};
export const callOpenAIDirect_Streaming:ChatAPICall = async function(messages:ChatCompletionMessageParam[], streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, chatAPIOptions:ChatAPIOptions=DEFAULT_CHAT_API_OPTIONS, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void):Promise<ChatAPICallReturnType> {
    // if (DEBUG) console.log("[callOpenAIDirect_Streaming]>Starting the call. messages: ",messages, "temperature: ",temperature);
    const streamingPayload = {
        ...getPayloadBaseFrom(messages, chatAPIOptions),
        stream: true,
    } as ChatCompletionCreateParamsStreaming;
    return await callChatAPIWithRetries_Streaming(streamingPayload, streamingCallback, errorCallback, callFunction);
};