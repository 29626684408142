import { useContext } from "react";
import { useAddNoteToParent } from "../../Notes/Data/Actions/Tree/ManipulateTree";
import { useNewNote } from "../../Notes/Data/NoteDBHooks";
import { NotesContext } from "../../Notes/Data/NotesContext";
import { useNavigateToNote } from "../Utilities/NavigateTo";


export function useNewNoteAction(parent_note_id: string) {
  const newNote = useNewNote();
  const notesContext = useContext(NotesContext);
  const navigateToNote = useNavigateToNote();
  const addNoteToParent = useAddNoteToParent();

  async function onNewNoteClicked() {
    // Temporary new names
    const newNoteName = prompt("New note name", "Untitled");
    if (!newNoteName) return; // Cancelled

    // TODO since this is now async, we could wait with a progress indicator.
    var newNoteProps: any = {};
    if (parent_note_id) {
      // If we are in a document, we want to create the new note in the same folder.
      // addNoteToParent handles this case, but adding here saves one update by putting the parent in create time.
      newNoteProps.parent = parent_note_id;
    }
    const note = await newNote(newNoteName, newNoteProps);
    // Now that we have the ID, we can add it to the parent:
    if (parent_note_id)
      addNoteToParent(note, parent_note_id);
    // Preserve Search query, etc., but navigate to the new note:
    navigateToNote(note.id);
    notesContext.notesHaveBeenLoaded([note]); // so it shows in the tree, etc.
  }
  return onNewNoteClicked;
}
