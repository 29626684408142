import { DefenseCard, XPAtLeast1 } from "./GameCardTypes";
import { produceLevelCards } from "./ReusableCards";

// TODO redesign this.
// export const Defense_Stealth3_SneakyKnifeThrow3 = {
//     name: "Sneaky Knife Throw 3",
//     minLevel: 2,
//     commandString: "**If you took no actions (not even rest) last round:** "+
//     "Up to **3** times this round, after an enemy finishes an action that ends up to **3 spaces** away, you may:\n\n"+
//     "⚡: 🎯 Target enemy takes ❤️ **1d4** piercing (physical) damage.",
//     xpCost: 2,
//     goldCost: 0,
//     applicableToDisciplines: ["Stealth"],
// } as DefenseCard;

export function DefenseArmor(level: XPAtLeast1): DefenseCard {
    const namesByLevel = ["Dinged Up Shield (dmg-1)", "Shield (dmg-2)", "Reinforced Shield", "Steel Shield", "Armor Set", "Reinforced Armor Set", "Steel Armor Set", "Mithril Armor Set"];
    let name;
    if (level <= namesByLevel.length)
        name = namesByLevel[level-1];
    else
        name = namesByLevel[namesByLevel.length-1];

    return {
        name,
        minLevel: level,
        commandString: "🛡️ **Shield " + level + "**: 🛡️ When you are attacked **physically** you may take ❤️ **" + level + "** less damage.",
        xpCost: level,
        goldCost: 10,
        applicableToDisciplines: [],
    } as DefenseCard;
}

/*********
 * TODO we want this upgrade to show up only if the player has a previous level or has a skeleton background...
 */
export function SkeletalArmor(level: XPAtLeast1): DefenseCard {
    const defenseShield = DefenseArmor(level);
    defenseShield.name = "Skeletal Armor " + level;
    // defenseShield.commandString = defensShield.commandString.replaceAll("Shield", "Armor");
    return defenseShield;
}

export const DefenseCardFunctions = [DefenseArmor] as ((level: XPAtLeast1) => DefenseCard)[];

export function getAllDefenseCards(level: XPAtLeast1): DefenseCard[] {
    const cards = [] as DefenseCard[];
    for (const cardFunction of DefenseCardFunctions) {
        cards.push(...produceLevelCards<DefenseCard>(cardFunction,level))
    }
    return cards;
}