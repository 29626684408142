import { DiscardableCard, PositiveNumberTo20 } from "./GameCardTypes";

import PlaceholderImg from "./CardImages/SD/Barricade.png";
import { getD4D20D100ForAvgRoll, parseDice } from "./DiceNotation";
import { getDamagePerSpace } from "./CardPoints";
import { produceLevelCards } from "./ReusableCards";

export function HealingPotion(level: PositiveNumberTo20): DiscardableCard {
    const healPerAffectedSpace = getDamagePerSpace(level, 0, 1, 1, 3/*put it at the equivalent of a HealingTouch*/,0/*less powerful than healing touch*/);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(healPerAffectedSpace);
    return {
        name: "Healing Potion "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 3*level,
        minLevel: level,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange: 0,
        maxRange: 1,
        dicePerAffectedSpace: dicePerAffectedSpace,

        commandAreaString: `You 🎯`,

        commandString: `💨 This does not use up your turn.\n\n🤝 Select an ally 0-1 space away (even you) heals 🧡 **%dice%**.\n\n🗑 Discard this.`,
    };
}
export function EnergyPotion(level: PositiveNumberTo20): DiscardableCard {
    const healPerAffectedSpace = getDamagePerSpace(level, 0, 1, 1, 3/*put it at the equivalent of a HealingTouch*/,0/*less powerful than healing touch*/);
    const dicePerAffectedSpace = getD4D20D100ForAvgRoll(healPerAffectedSpace);
    return {
        name: "Energy Potion "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 6*level,
        minLevel: (level*2+1) as PositiveNumberTo20, // Energy potion is rare, so it's a higher level than healing potion

        moveSpaces: 0,
        spacesAffected: 1,
        minRange: 0,
        maxRange: 1,
        dicePerAffectedSpace: dicePerAffectedSpace,

        commandAreaString: `You 🎯`,

        commandString: "💨 This does not use up your turn.\n\n🤝 Select an ally 0-1 space away (even you) gains "+("⚡".repeat(level))+" "+level+" Energy.\n\n🗑 Discard this.",
    };
}
export function SkillBoost(level: PositiveNumberTo20): DiscardableCard {
    return {
        name: "Skill Boost "+level,
        actionType: "enhance",
        image: PlaceholderImg,

        goldCost: 4*level,
        minLevel: level+1 as PositiveNumberTo20,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange: 0,
        maxRange: 1,

        commandAreaString: `You 🎯`,

        commandString: "💨 This does not use up your turn.\n\n🤝 Select an ally 0-1 space away (even you) next skill roll gains "+level+" extra dice.\n\n🗑 Discard this.",
    };
}

export const AllDiscardableCardFunctions=[HealingPotion,EnergyPotion, SkillBoost] as ((level:PositiveNumberTo20)=>DiscardableCard)[];

export function getAllDiscardableCards(level: PositiveNumberTo20): DiscardableCard[] {
    const cards = [] as DiscardableCard[];
    for (const cardFunction of AllDiscardableCardFunctions) {
        cards.push(...produceLevelCards<DiscardableCard>(cardFunction,level))
    }
    return cards;
}