import { useMediaQuery } from "react-responsive";

// isSmallScreen successfully detects even a larger screened phone, and smaller monitors too.
export function useIsSmallScreen() {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' });
    return isSmallScreen;
}

// isPortrait isn't a great check on some mobile devices with unusual screen sizes.
// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
