import _ from "lodash";
import { useContext, useMemo } from "react";
import { NoteTreeElement } from "../../../User/Data/TagTreeStateType";
import { useRootParentNotes } from "../../Data/NoteDBHooks";
import { NotesContext, NotesContextType } from "../../Data/NotesContext";
import { Note } from "../../Data/NoteType";
import { useAddNoteToParent } from "../../Data/Actions/Tree/ManipulateTree";
import { Extension } from "../../../Extensions/ExtensionsFramework/ExtensionsList";
import { getEmojiFor } from "../../../Extensions/ExtensionsFramework/ExtensionsSchemas";
import { NoteHierarchyContext } from "../../UIs/NoteInformationComponents/NoteHierarchyProvider";

export async function getNoteEmoji_async(note:Note, notesContext:NotesContextType):Promise<string> {
    // Get the note icon, if it's loaded, if we can.
    // It can have its own emoji. We encourage this more if it's a Type note itself, but it's possible otherwise, and this would override the emoji of the type.
    if (!note)
        return "";
    if (note.emoji)
        return note.emoji;
    if (!note.type)
        return "";
    const typeNote = await notesContext.getNoteOfNameAndType(note.type, "Type");
    if (typeNote && typeNote.emoji)
        return typeNote.emoji;
    return "";
}

export function getNoteEmoji_withLoadedNotesOnly(note:Note, notesContext:NotesContextType):string {
    // Get the note icon, if it's loaded, if we can.
    // It can have its own emoji. We encourage this more if it's a Type note itself, but it's possible otherwise, and this would override the emoji of the type.
    if (!note)
        return "";
    if (note.emoji)
        return note.emoji;
    if (!note.type)
        return "";
    const typeNote = notesContext.getLoadedNoteOfNameAndType(note.type, "Type");
    if (typeNote && typeNote.emoji)
        return typeNote.emoji;
    return "";
}

/*******
 * Used to render the name of the note, with the emoji of the type, if it has one.
 */
export function getNoteNameAndEmoji(note:Note, notesContext:NotesContextType, extensions:Extension[]):string {
    const {note_title} = getNoteNameAndEmoji_multipurpose(note, notesContext, extensions);
    return note_title;
}


export async function getNoteNameAndEmoji_async(note:Note, notesContext:NotesContextType):Promise<string> {
    const emoji = await getNoteEmoji_async(note, notesContext);
    let note_title = note.doc_name;
    if (emoji)
        // note_title = note_title + " " + emoji;
        note_title = emoji+ " " +note_title;
    return note_title;
}


export function getNoteEmoji_multipurpose(note:Note, notesContext:NotesContextType, extensions:Extension[]) {
    // Get the note icon, if it's loaded, if we can.
    // It can have its own emoji. We encourage this more if it's a Type note itself, but it's possible otherwise, and this would override the emoji of the type.
    // if (!note)
    //     return {emoji:"", fullyLoaded:false};
    if (note.emoji)
        return {emoji:note.emoji, fullyLoaded:true};
    if (note.template_doc_id) {
        let extensionEmoji = getEmojiFor(extensions, note);
        if (extensionEmoji)
            return {emoji:extensionEmoji, fullyLoaded:true};
    }
    if (!note.type)
        return {emoji:"", fullyLoaded:true};
    const typeNote = notesContext.getLoadedNoteOfNameAndType(note.type, "Type");
    if (!typeNote) {
        return {emoji:"", fullyLoaded:false, getFullyLoaded:async function() {
            const typeNote = await notesContext.getNoteOfNameAndType(note.type, "Type");
            if (typeNote && typeNote.emoji)
                return {emoji:typeNote.emoji, fullyLoaded:true};
            return {emoji:"", fullyLoaded:true};
        }};
    }
    if (typeNote.emoji)
        return {emoji:typeNote.emoji, fullyLoaded:true};
    return {emoji:"", fullyLoaded:true};
}

export function getNoteNameAndEmoji_multipurpose(note:Note, notesContext:NotesContextType, extensions:Extension[]) {
    const {emoji, fullyLoaded, getFullyLoaded} = getNoteEmoji_multipurpose(note, notesContext, extensions);
    if (!fullyLoaded) { // 
        return {note_title:note.doc_name, fullyLoaded, text:note.doc_name, getFullyLoaded:async function() {
            //@ts-ignore for typescript only. getFullyLoadid is always defined when !fullyLoaded.
            const {emoji, fullyLoaded} = await getFullyLoaded();
            let note_title = note.doc_name;
            if (emoji)
                note_title = emoji+ " " +note_title;
            return {note_title, fullyLoaded, text:note.doc_name};        
        }};
    }
    let note_title = note.doc_name;
    if (emoji)
        note_title = emoji+ " " +note_title;
    return {note_title, fullyLoaded: true};
}

function getTreeElementFromNote(note:Note, uniqueNoteIDs:string[], notesContext:NotesContextType, expandedKeys:string[], extensions:Extension[]):NoteTreeElement {
    // Check whether all children are loaded... if not, request and show what we've loaded so far.
    const childrenNotes = [] as Note[];
    if (note.children) {
        const childrenIds = _.uniq(note.children); // dedup, just in case it's corrupted in some way.
        for (const childId of childrenIds) {
            const childNote = notesContext.getLoadedNote(childId);
            if (childNote) {
                // To reduce the negative impact of a corrupted tree with duplicated nodes, we don't include duplicates. Antd gets very messed up if there's two nodes with the same ID.
                // We haven't tested this code, but we've seen that without it, it gets messed up.
                if (!uniqueNoteIDs.includes(childNote.id)) {
                    childrenNotes.push(childNote);
                    uniqueNoteIDs.push(childNote.id);
                }
            } else {
                // TODO this is where we stop if it's not expanded. We need to know if we're expanded at this point.
                if (expandedKeys.includes(note.id))
                    notesContext.loadNoteID(childId);
            }
        }
    }
    // These show in the order that they're in the children list. So we can reorder by changing the order of the children list.
    const children = childrenNotes.map(function(cn){return getTreeElementFromNote(cn,uniqueNoteIDs,notesContext, expandedKeys,extensions)});
    for (const childNote of childrenNotes) {
        uniqueNoteIDs.push(childNote.id);
    }
    return {
        title:getNoteNameAndEmoji(note, notesContext, extensions),
        key:note.id,
        children,
        type:"Note",
        isLeaf:(note.children && note.children.length===0)} as NoteTreeElement;
}

function onDrop(dropEvent:any, addNoteToParent:any, notesContext:NotesContextType) {
    //TODO there is a bug in here, somewhere. Sometimes after a drag & drop, the note sticks around in its usual place and is misaligned with the tree.


    ///////////////////////////////////
    // Extract the info from the event
    let dropKey;
    let dropPosition;
    const dropPos = dropEvent.node.pos.split('-');
    if (dropEvent.dropToGap) {
        // TODO check if this is right always...
        const lookUpChildNoteId = dropEvent.node.key;
        const lookUpChildNote = notesContext.getLoadedNote(lookUpChildNoteId);
        dropKey = lookUpChildNote.parent;
        dropPosition = dropEvent.dropPosition;
    } else {
        dropKey = dropEvent.node.key;
        // This next line is very strange -- and as is it only sometimes works. There's some strange quirks in how ant specifies the dropPos above -- it's not intutitve
        // and sometimes even doesn't contain the last level if the number is 0, which means it needs to be adjusted. This does the trick.
        dropPosition = dropEvent.dropPosition - Number(dropPos[dropPos.length - 1]);
    }
    const dragKey = dropEvent.dragNode.key;

    ///////////////////////////////////
    // Save the info about who the parent is to firestore:
    const childNote = notesContext.getLoadedNote(dragKey);
    if (!childNote) {
        // bug somewhere!
        debugger;
        return;
    }

    addNoteToParent(childNote, dropKey, dropPosition);

    // TODO save the order of the nodes somewhere -- perhaps personal, perhaps shared. Needs to be a float, not a small integer, to support multi-user ordering, if we do that.
    // Personal seems like it would be best at the top level, so you can order your hierarchy.
    // But shared seems like it would work best for shared groups, so the most important notes can be listed first.

    /////////////////////////////////////////
    // The rest appears to all be about editing the treeNodes. This may become obsolete when we do firestore driven hierarchies.
    // const newTreeNodes = [...treeNodes];

    // // Find dragObject
    // let dragObj:NoteTreeElement;
    // findKeyInTree(newTreeNodes, dragKey, (item:any, index:number, arr:NoteTreeElement[]) => {
    //   arr.splice(index, 1);
    //   dragObj = item;
    // });

    // if (!dropEvent.dropToGap) {
    //   // Drop on the content
    //   findKeyInTree(newTreeNodes, dropKey, (item:any) => {
    //     item.children = item.children || [];
    //     // where to insert
    //     item.children.unshift(dragObj);
    //   });
    // } else if (
    //   (dropEvent.node.props.children || []).length > 0 && // Has children
    //   dropEvent.node.props.expanded && // Is expanded
    //   dropPosition === 1 // On the bottom gap
    // ) {
    //   findKeyInTree(newTreeNodes, dropKey, (item:any) => {
    //     item.children = item.children || [];
    //     // where to insert
    //     item.children.unshift(dragObj);
    //     // in previous version, we use item.children.push(dragObj) to insert the
    //     // item to the tail of the children
    //   });
    // } else {
    //   let ar;
    //   let i;
    //   findKeyInTree(newTreeNodes, dropKey, (item:any, index:number, arr:any[]) => {
    //     ar = arr;
    //     i = index;
    //   });
    //   if (dropPosition === -1) {
    //     //@ts-ignore
    //     ar.splice(i, 0, dragObj);
    //   } else {
    //     //@ts-ignore
    //     ar.splice(i + 1, 0, dragObj);
    //   }
    // }

    // // Now save the new tree:
    // const toSaveAsString=JSON.stringify({treeNodes: newTreeNodes});
    // // console.log("About to save tagtree as "+toSaveAsString);
    // const doc_data=JSON.parse(toSaveAsString);
    // saveTagTreeState(doc_data);
  };


export function useCreateTreeNodes(expandedKeys:string[]):{treeNodes:NoteTreeElement[],onDrop:any} {
    const addNoteToParent = useAddNoteToParent();
    // We'll keep track of the notes we've loaded, so we can use them in various places.
    // Note -- once it's loaded, it doesn't unload. That seems fine.
    const notesContext = useContext(NotesContext);
    const {extensions} = useContext(NoteHierarchyContext);

    const toReturn = {} as any;

    // V2 - build tree from Note parents, using whatever data we have available.
    const parentNotes = useRootParentNotes();
    // const parentIDs = parentNotes?.map(function(note:Note){ return note.id});
    // const allChildren = useNotesWithParents(parentIDs);
    // Notify about the parent notes... hopefully it doesn't cause recursion...

    // TODO we can change this to a useEffect, and then we can remove the setTimetout, I think.
    useMemo(function() {
        if (parentNotes && parentNotes.length>0) {
            // Send notice, but wait until the next render -- if we didn't delay, react would be getting a setState mid-render.
            setTimeout(function(){
                notesContext.notesHaveBeenLoaded(parentNotes)},0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[parentNotes]);
    toReturn.treeNodes = useMemo(
        function processTreeNodes(){
        const newTreeNodes = [] as NoteTreeElement[];
        if (!parentNotes || parentNotes.length===0)
            return newTreeNodes;
        const uniqueNoteIDs = [] as string[];
        for (const parentNote of parentNotes) {
            uniqueNoteIDs.push(parentNote.id);
        }
        for (const parentNote of parentNotes) {
            // This will need to change to do dynamic loading:
            
            // const childrenHere = childNotes.filter(function(childNote:Note){return childNote.parent===parentNote.id});
            // const childrenHere = allChildren.filter(function(childNote:Note){return childNote.parent===parentNote.id});
            newTreeNodes.push(getTreeElementFromNote(parentNote,
                uniqueNoteIDs, notesContext, expandedKeys,extensions));
        }
        // TODO we need to push children of expanded nodes onto the tree under their parent.
        return newTreeNodes;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[parentNotes, notesContext.loadedNotes, expandedKeys]);
    // toReturn.treeNodes = processTreeNodes();
    
    toReturn.onDrop=function(dropEvent:any) {
        onDrop(dropEvent, addNoteToParent, notesContext)
    }   

    return toReturn;
}