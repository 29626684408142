import { getAvgRoll } from "./DiceNotation";
import { getActionCardMove, ReusableCard, XPAtLeast1 } from "./GameCardTypes";


/********************
 * getBaseDamagePerLevel
 * 
 * Designed with the constraints:
 * - Starts with D4s
 * - Minimum after some modifiers applied is 1d4-1 aka 1.5
 * - Even with an spacesAffected of 5 it's still going up by 0.5 each level. (This breaks down at 6 spaces affected)
 */
function getBaseDamagePerLevel(level:XPAtLeast1):number {
    // let dmg = 1.5; // 1d4-1
    // dmg += level*2.5; // Level 1 base is 4, level 2 base is 6.5, level 3 base is 9, etc.

    // We want damage to scale non-linearly, e.g. as a proportion of the previous level. It starts at 2.5 for level 1, and goes up by 0.5 each level.
    const dmg = 1.5+level*(2.5 + level/6);
    return Math.round(dmg*2)/2; // round to nearest 0.5
}

export function getExpectedTotalEnemyHPPerPC(level:XPAtLeast1):number {
    // At level 1, the goal is that a PC can take out all the enemies in 5 attacks.
    // At level 10+, the goal is that a PC can take out all the enemies in 2 attacks. Should feel like we're making progress each level, enemies getting just a tiny bit easier.
    // Scaling in between, that means:
    let targetAttacks = 5 - (level-1)*0.3;
    if (targetAttacks<2)
        targetAttacks = 2;
    const damagePerTurn = getBaseDamagePerLevel(level);

    return targetAttacks * damagePerTurn;
}

// Print out a simple TSV of expected enemy HP per PC for each level.
// function printExpectedTotalEnemyHPPerPCPerLevel() {
//     let tsvStr = "";
//     tsvStr+="Level\tDmg\tEnemy HP per PC\n";
//     for (let level=1; level<=20; level++) {
//         const dmg = getBaseDamagePerLevel(level as XPAtLeast1);
//         const enemyHpPerPC = getExpectedTotalEnemyHPPerPC(level as XPAtLeast1);
//         tsvStr += level+"\t"+dmg+"\t"+Math.round(enemyHpPerPC)+"\n";
//     }
//     console.log("",tsvStr);
// }
// printExpectedTotalEnemyHPPerPCPerLevel();



/*****************************
 * getExpectedSpacesAffected
 * 
 * This is a guess at how many spaces will be affected by an area of effect attack.
 * 
 */
function getExpectedSpacesAffected(spacesAffected:number):number {
    if (spacesAffected<=2)
        return spacesAffected;
    // For more than 2 spaces, we assume we're not hitting every square, but it does make it easier to hit enemies.    
    // We think they can always hit 2 when using an area attack, otherwise we wouldn't use it. So the main goal is hitting at least two people.
    // But then above that, it scales with a probability of ~0.3 per additional space.
    return 2 + (spacesAffected-2)*0.2;
}
function getExpectedSpacesAdjustment(spacesAffected:number):number {
    if (spacesAffected===1)
        return 1;
    // Starting at 2, the base is 0.8. It's more total damage than attacking one space, but still significantly less per space.
    // But for each additional space we reduce by 0.05:
    return 0.7 - (spacesAffected-2)*0.05;
}

/*************
 * getDamageForAction
 * This is effectively the inverse of getCardEquivalentPoints.
 * 
 * TODO update BattleNoteTypeEditor's ENEMY_TOTAL_HP_PER_PC_By_Level based on this calculation.
 * TODO update the point
 */
export function getDamagePerSpace(level:XPAtLeast1, minRange:number, maxRange:number, spacesAffected:number, moveSpaces:number, advantageModifier:number=0):number {
    // The most damage is to a single adjacent enemy.
    // Then we'll adjust from that point.

    let dmg = getBaseDamagePerLevel(level);

    // Adjust for maxRange. Longer range is an advantage, so for each increase in maxRange you get less damage.
    // But, we don't always hit at that max range, players can hit at a bit less than it.
    if (maxRange===1) {
        dmg += 0.5; // +0.5 because you're close up and personal, so you're taking on extra risk. This means going from 1-2 is the biggest difference, a whole point.
    } else {
        // As range gets larger, you're more able to hit enemies while staying out of range of taking damage. So you get less damage per space.
        dmg -= (maxRange-1)*1.2;
    }

    // Adjust for minRange. Having a larger minRange is a disadvantage, because you can't hit things as close to you. So you get more damage for each increase in minRange.
    dmg += (minRange-1)/2; // +0.5 for each increase in minRange

    // Factor of 1 or less, to reduce the damage per space for each additional space affected.
    const expectedSpacesAdjustment = getExpectedSpacesAdjustment(spacesAffected);
    dmg *= expectedSpacesAdjustment;

    // Adjust for move. Moving is an advantage, so higher moves lead to lower damage. 3 is neutral
    // At higher levels, the move is more of a relative benefit.
    dmg -= (moveSpaces-3) + (moveSpaces-3)*level/4;

    // Adjust for modifier. Modifiers are considered an advantage. For example, a heal is stronger than an attack, so Heal gets a positive modifier to allow for more healing.
    dmg += advantageModifier;

    return dmg;
}

// Print a Tab separated table of damage for level & spacesAffected. Level is rows, spacesAffected is columns.
// function printLevelXSpacesAffected() {
//     let tsvStr = "";
//     tsvStr+="Level\tA=1\tA=2\tA=3\tA=4\tA=5\tA=6\n";
//     for (let level=1; level<=10; level++) {
//         let str = level+"";
//         for (let spacesAffected=1; spacesAffected<=6; spacesAffected++) {
//             const dmgPerSpace = getDamagePerSpace(level as XPAtLeast1,1,1,spacesAffected,3);
//             const expectedAffectedSpaces = getExpectedSpacesAffected(spacesAffected);
//             const dmg = dmgPerSpace * expectedAffectedSpaces;
//             let dmgStr = "";
//             if (dmg<1.5)
//                 dmgStr = "";
//             else 
//                 dmgStr = Math.round(dmg/0.5)*0.5+"";
//             str += "\t"+dmgStr;
//         }
//         tsvStr += str + "\n";
//     }
//     console.log("Level X spacesAffected ",tsvStr);
// }
// printLevelXSpacesAffected();


// Print a Tab separated table of damage for level & moveSpaces. Level is rows, moveSpaces is columns.
// function printLevelXMoveSpaces() {
//     let tsvStr = "";
//     tsvStr+="Level\tM=1\tM=2\tM=3\tM=4\tM=5\tM=6\n";
//     for (let level=1; level<=10; level++) {
//         let str = level+"";
//         for (let moveSpaces=1; moveSpaces<=6; moveSpaces++) {
//             const dmg = getDamagePerSpace(level as XPAtLeast1,1,1,1,moveSpaces);
//             let dmgStr = "";
//             if (dmg<1.5)
//                 dmgStr = "";
//             else 
//                 dmgStr = Math.round(dmg/0.5)*0.5+"";
//             str += "\t"+dmgStr;
//         }
//         tsvStr += str + "\n";
//     }
//     console.log("Level X moveSpaces ",tsvStr);
// }
// printLevelXMoveSpaces();

// Print a Tab separated table of damage for level while increasing range.
// minRange starts at 1 (column 1), then goes to 2 and stays at 2.
// maxRange increases at 1 per column
// function printLevelXRange() {
//     let tsvStr = "";
//     tsvStr+="Level\tR=1\tR=2\tR=3\tR=4\tR=5\tR=6\n";
//     for (let level=1; level<=10; level++) {
//         let str = level+"";
//         for (let maxRange=1; maxRange<=6; maxRange++) {
//             let minRange = 1;
//             if (maxRange>=2)
//                 minRange = 2;
//             const dmg = getDamagePerSpace(level as XPAtLeast1,minRange,maxRange,1,3);
//             let dmgStr = "";
//             if (dmg<1.5)
//                 dmgStr = "";
//             else 
//                 dmgStr = Math.round(dmg/0.5)*0.5+"";
//             str += "\t"+dmgStr;
//         }
//         tsvStr += str + "\n";
//     }
//     console.log("Level X maxRange ",tsvStr);
// }
// printLevelXRange();



/*************
 * getCardEquivalentPoints
 * 
 * Use a point system to compare cards.
 * We compare cards at each level (per XP).
 * 
 * Move:
 *      Minimum move is 2 (-1 points)
 *      Each additional move = 1 point
 * 
 * Damage distance:
 *      Base minRange & maxRange of 0 or 1 = 0 points
 *      For each minRange above 1 = -1 point
 *      For each maxRange above minRange = +1 point
 * 
 * Damage:
 *      Calculate damage using getAvgRollD(dice)
 *      Base damage average of 1.5 (average of 1d4 is 2.5. Minimum is 1d4-1 which is 1.5)
 *      Each additional 2.5 = 1 point
 *      for example, a -1 = -1/2.5 = 0.4 point
 * 
 * 
 * Spaces affected:
 *      Base spaces affected is 1
 *      If there are additional spaces, multiply damage points by spaces affected / 2 (because they might not all be full)
 *      
 */
export function getReusableCardEquivalentPoints(card:ReusableCard) {
    let points = 0;
    points += getActionCardMove(card) - 3;
    if (card.minRange>1)
        points -= (card.minRange - 1)*.5;
    if (card.maxRange && card.maxRange>1) {
        if (card.actionType==="attack")
            // Attacks scale at ?1? .6 point per range
            points += (card.maxRange - 1)*.9;
        else
            // Non-attacks scale at 0.5 points per range
            points += (card.maxRange - 1)/2;
    }
    if (card.dicePerAffectedSpace) {
        let dmgPoints = (getAvgRoll(card.dicePerAffectedSpace) - 1.5);
        if (card.spacesAffected===2)
            dmgPoints *= card.spacesAffected;
        else if (card.spacesAffected>2) {
            // We assume that if it's 2 squares, it'll usually be used for 2. if it's more than 2, it'll be gradually less effective, like 3 squares is often used for just 2 of the 3.
            dmgPoints *= card.spacesAffected/1.3; // 1.5 would make going from 2 spaces to 3 spaces neutral, but I think it's still a bit better to have 3 spaces.
        }
        if (card.discipline==="Telepath")
            // TODO this should be tuned more, I don't think this is quite the right point calculation. But positioning an enemy so he could be hit by more of us is potentially quite powerful, more so than the actual damage.
            dmgPoints = dmgPoints/1.5; // telepath controls enemies, so it's more powerful than a standard attack.
        else if (card.actionType==="attack")
            dmgPoints = dmgPoints/2.5; // convert from damage dice to points.
        else if (card.discipline==="Healing")
            dmgPoints = dmgPoints/4; // healing gets more dice than attacks
        points += dmgPoints;
    }
    if (card.commandString.includes("🔥 fire") || card.commandString.includes("❄️ cold"))
        points+=1; // these are easier to double the damage from.
    return points;
}




/*********************
 * The following are used for debugging & testing card balance.
 */
// function getReusableCardEquivalentPointsString(card:ReusableCard) {
//     const points = getReusableCardEquivalentPoints(card);
//     // console.log("points:",points);
//     const cardNameStr = `${card.name} ${card.xpCost} (${card.discipline})`;
//     // console.log("cardNameStr: ",cardNameStr);
//     const MAX_CARD_NAME_LENGTH=40;
//     if (cardNameStr.length>MAX_CARD_NAME_LENGTH) {
//         throw Error("BUG! Need to increase card length from "+MAX_CARD_NAME_LENGTH+" to "+cardNameStr.length+"!");
//     }
//     const padding = " ".repeat(MAX_CARD_NAME_LENGTH-cardNameStr.length);
//     // console.log("padding: \""+padding+"\"");
//     const str = cardNameStr+":"+padding+points+" points";
//     // console.log("final string: ",str);
//     return str;
// }
// console.log(getCardEquivalentPointsString(DustDevilsVeil1));
// function printPointsForCardsOfXP(xp:number) {
//     console.log(`**Cards of XP ${xp}:**`);
//     for (let index=0; index<AllActionCards.length; index++) {
//         const card = AllActionCards[index];
//         // console.log(index+" "+card.name+card.xpCost,card);
//         if (card.actionType!=="attack")
//             continue; // for now, only print attacks. the others are not equivalents.
//         if (card.xpCost===xp && (!card.enhancements || card.enhancements.length===0)) {
//             console.log("\t"+getReusableCardEquivalentPointsString(card))
//         }
//     }
// }
// printPointsForCardsOfXP(3);
