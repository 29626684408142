import { Firestore, runTransaction } from "firebase/firestore";
import { useContext } from "react";
import { useFirestore } from "reactfire";
import { getRef_NoteID } from "../../NoteDBHooks";
import { NotesContext, NotesContextType } from "../../NotesContext";
import { Note, PartialNote } from "../../NoteType";

/*********
 * This is most useful when moving a node, e.g. drag & drop to a new location.
 * 
 * For brand new nodes the process is a bit simpler, e.g. in DiffJsonFormsObject
 */
export async function addNoteToParent(firestore:Firestore, childNote:Note,
    new_parent_note_id:string, dropPosition:number | null = null,
    notesContext:NotesContextType) {
        
    let childToSave = {parent:new_parent_note_id} as PartialNote | null;
    const childNoteRef = getRef_NoteID(firestore, childNote.id);

    // const childNote = notesContext.getLoadedNote(child_note_id);
    let newParentNote = null as Note | null;

    // If there's an old note, we replace that.
    // TODO this would be better loaded inside the transaction, to make sure
    // we get the right one, if there are multiple editors.
    let oldParent = null as PartialNote | null;
    let oldParentRef = null as any;
    let oldParentId=childNote.parent;
    let oldParentToSave = null as PartialNote | null;
    if (oldParentId===new_parent_note_id) {
        // We don't have to save the old parent.
        // We still need to check if it's in the same position or a different one, and save the newParent...

        // That happens later. Don't change the old parent separately from the new, esp. not right after:
        oldParentId = undefined;
        // Also, nothing to save for the child:
        childToSave = null;
    }
    if (oldParentId) {
        oldParent = notesContext.getLoadedNote(oldParentId);
        if (!oldParent) {
            // The parent has been deleted. This suggests corruption. However, we can fix the corruption by ignoring it and putting this note to its new parent.
        } else {
            oldParentRef = getRef_NoteID(firestore, oldParentId);
            let oldChildren = oldParent.children;
            if (oldChildren) {
                const oldIndex = oldChildren.indexOf(childNote.id);
                if (oldIndex!==-1) {
                    oldChildren.splice(oldIndex,1);
                    oldParentToSave = {children:oldChildren} as PartialNote;
                }
            }
        }
    };

    let children=[childNote.id];
    let newParentToSave = null as PartialNote | null;
    if (new_parent_note_id==="") {
        // it's the root! Special case.
        // No need to save to the new parent, as it just needs to be changed to "" as a child.
    } else {
        newParentNote = notesContext.getLoadedNote(new_parent_note_id);
        if (!newParentNote) {
            // if we reach here, there is a bug elsewhere. they should be all loaded.
            debugger;
            return;
        }    
        if (newParentNote.children) {
            if (newParentNote.children?.includes(childNote.id) && newParentNote.children?.indexOf(childNote.id)===dropPosition) {
                // Okay, nothing to change in the new parent, it's already right.
                // Cue not to save this:
                // newParentToSave = null -- already set above.
            } else {
                if (newParentNote.children?.includes(childNote.id)) {
                    // Remove it first! No matter how many times it appears, remove all:
                    children = [...newParentNote.children.filter(function(childId:string){return childId!==childNote.id})];
                    // We also need to adjust the dropPosition:
                    if (dropPosition) {
                        // If and only if the old position was earlier than the new position, we need to reduce it.
                        for (let i=0; i<dropPosition && i<newParentNote.children.length; i++) {
                            if (newParentNote.children[i]===childNote.id)
                                dropPosition--;
                            // dropPosition = dropPosition - (newParentNote.children.length - children.length);
                        }
                    }

                } else 
                    children = [...newParentNote.children];
                if (dropPosition===null || dropPosition>=newParentNote.children.length)
                    children.push(childNote.id);
                else
                    children.splice(dropPosition,0,childNote.id);
                newParentToSave = {children} as PartialNote;
            }
        } else {
            newParentToSave = {children} as PartialNote;
        }
    }

    await runTransaction(firestore, async (transaction) => {
        if (newParentToSave) {
            const newParentRef = getRef_NoteID(firestore, new_parent_note_id);
            transaction.update(newParentRef, newParentToSave);
        }
        if (oldParentToSave)
            transaction.update(oldParentRef,oldParentToSave);
        if (childToSave)
            transaction.update(childNoteRef, childToSave);
    });
    // Update the tree etc.
    if (oldParentToSave /*for typescript:*/ && oldParentId)
        notesContext.mergeChangesIntoLoadedNote(oldParentId, oldParentToSave);
    if (newParentToSave)
        notesContext.mergeChangesIntoLoadedNote(new_parent_note_id, newParentToSave);
    if (childToSave)
        notesContext.mergeChangesIntoLoadedNote(childNote.id, childToSave);
}

export function useAddNoteToParent() {
    const firestore = useFirestore();
    const notesContext = useContext(NotesContext);

    return async function addNoteToParent_wrapper(childNote:Note,
        new_parent_note_id:string, dropPosition:number | null = null) {
            return addNoteToParent(firestore,childNote,new_parent_note_id,dropPosition,notesContext);
        }
}