import { JSONSchemaType } from "ajv";
import { DustDevilsVeil1, Rest0 as Rest0Breather,  ArcheryArrow, ConstructBarricade, DestructExplode, BarbaricAxeSwing, BarbaricSwordSlice, TentacleAttack, SneakyKnifeThrow, HealingTouch, FireWizardryFireDart, IceWizardryIceDart, FightingOneTwoPunch, DragonbornFirebreath, AthleticRun, TelepathMindControl } from "../GameCard/ReusableCards";
import { Sword, TentacleSpikes, cardAddEnhancements } from "../GameCard/ReusableCardEnhancements";
import { DefenseCard, DiscardableCard, ReusableCard } from "../GameCard/GameCardTypes";
import { DisguiseSkill, InfluenceSkill, OutdoorsSkill, PhysicalSkill, SensesSkill, StealthSkill } from "../GameCard/SkillCards";
import { ExtensionJSONFormsObject } from "../../ExtensionsFramework/ExtensionsList";
import { Layout } from "@jsonforms/core";
import { HealingPotion } from "../GameCard/DiscardableCards";
import { SkeletalArmor } from "../GameCard/DefenseCards";

const TsuiLetourneauSupporters = "7 Ogrukh (4 berserkers, 3 scouts), Alaric Eldridge Thorne (Master Alchemist of Sylvanholme), Elysia (Lensmaker of Sylvanholme)";

type EvenNumberUpTo50 = 0|2|4|6|8|10|12|14|16|18|20|22|24|26|28|30|32|34|36|38|40|42|44|46|48|50;

// TODO We want to create some sort of way to track the player's own experience playing, so that we can adjust the game and the instructions to their skill level.
// export type PlayerGameExperience = {
//   playerName: string;
//   skillSystem: XPAtLeast0;
//   moveSystem: XPAtLeast0;
//   combatSystem: XPAtLeast0;
// }

// Root type includes everything besides cards. When stored in JSON, we store card names. This can be converted to the cards themselves.
export type PlayerCharacterRootType = {
    playerName:string,
    characterName:string,
    role?:string,

    earnedXP:number,
    earnedGold:number,
    spentGold:number, // Money spent on things excluding cards (unusual items, boats, bribes, etc)
    
    addedLife: EvenNumberUpTo50,
    miscItems?:string,
    supporters?:string,
}
export type PlayerCharacterJSONType = PlayerCharacterRootType & ExtensionJSONFormsObject & {
    reusableCardIDs: {
        cardID:string,
        reusableCardEnhancementIDs: string[],
    }[],
    defenseCardIDs:string[],
};
export type PlayerCharacterInMemoryType = PlayerCharacterRootType & {
    reusableCards:ReusableCard[],
    defenseCards:DefenseCard[],
    discardableCards:DiscardableCard[],
};

export const PlayerCharacterSchema:JSONSchemaType<PlayerCharacterJSONType> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},

        playerName: {type: "string", nullable: false, description: "The name of the player controlling this character"},
        characterName: {type: "string", nullable: false, description: "The name of the fictional character (PC)"},
        role: {type: "string", nullable: true, description: "Like a class, but optional, and may change over time depending on the character's available actions."},
        earnedXP: {type: "number", nullable: false},
        earnedGold: {type: "number", nullable: false},
        spentGold: {type: "number", nullable: false},
        addedLife: {type: "number", nullable: false},
        reusableCardIDs: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    cardID: {type: "string", nullable: false},
                    reusableCardEnhancementIDs: {
                        type: "array",
                        items: {type: "string"},
                        nullable: false,
                    }
                },
                required: [],
            },
            nullable: false,
        },
        miscItems: {type: "string", nullable: true, description: "Any items the character is carrying that are not cards"},
        supporters: {type: "string", nullable: true, description: "Any NPCs or other characters that have committed to supporting this character"},
        defenseCardIDs: {
            type: "array",
            items: {type: "string"},
            nullable: false,
        }
    },
    required: [],
};
// Uncomment the following to get the JSON schema in the console, in the propert format so that it can be pasted into the JSONForms editor at https://jsonforms-editor.netlify.app/
// console.log("PlayerCharacterSchema:")
// console.log(PlayerCharacterSchema);

/**********
 * UI Schema
 * 
 * The typescript for the "Layout" in json forms is less than optimal.
 * We want to preserve editability in the JSON Forms Editor at https://jsonforms-editor.netlify.app/ so:
 * - We don't use the Layout type in the declaration itself, because that would force extra inline casts due to the unusual typescript written by JSON Forms Editor.
 * - Instead, we cast as unknown as Layout at the end
 * - We keep the double quotes around the "type" values, because that's how the JSON Forms Editor writes them.
 */
export const PlayerCharacterUISchema = {
  "type": "VerticalLayout",
  "elements": [
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/playerName"
        },
        {
          "type": "Control",
          "scope": "#/properties/characterName"
        }
      ],
      "label": ""
    },
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/earnedXP"
        },
        {
          "type": "Control",
          "scope": "#/properties/earnedGold"
        }
      ],
      "label": "Earnings"
    },
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/addedLife"
        },
        {
          "type": "Control",
          "scope": "#/properties/reusableCardIDs",
          "label": "Reusable Cards"
        },
        {
          "type": "Control",
          "scope": "#/properties/defenseCardIDs",
          "label": "Defense Cards"
        },
        {
          "type": "Control",
          "scope": "#/properties/miscItems"
        },
        {
          "type": "Control",
          "scope": "#/properties/supporters"
        }
      ],
      "label": "Growth"
    }
  ]
} as unknown as Layout;

export const AllPlayerCharacters:PlayerCharacterInMemoryType[] = [
  {
      // Template empty player 1
      playerName:"_____________",
      characterName:"____________",
      role: "",
      earnedXP: 4, /*Each player starts with 4 XP. Ref PlayerFunctions.ts */
      earnedGold:0,
      spentGold: 0,
      addedLife: 0,
      reusableCards:[
          Rest0Breather,
          // 4 starters oriented around sneaking / stealth
          SneakyKnifeThrow(1),
          FightingOneTwoPunch(1),
          HealingTouch(1),
          StealthSkill(1),
      ],
      defenseCards: [],
      discardableCards: [HealingPotion(1)],
      miscItems: "",
      supporters: ""
  },
  {
    // Template empty player 2
    playerName:"_____________",
    characterName:"____________",
    role: "",
    earnedXP: 4, /*Each player starts with 4 XP. Ref PlayerFunctions.ts */
    earnedGold:0,
    spentGold: 0,
    addedLife: 0,
    reusableCards:[
        Rest0Breather,
        // 4 with slow moving but powerful attacks, and a run to get into place.
        BarbaricSwordSlice(1),
        IceWizardryIceDart(1),
        AthleticRun(1),
        PhysicalSkill(1),
    ],
    defenseCards: [],
    discardableCards: [HealingPotion(1)],
    miscItems: "",
    supporters: ""
  },{
    // Template empty player 3
    playerName:"_____________",
    characterName:"____________",
    role: "",
    earnedXP: 4, /*Each player starts with 4 XP. Ref PlayerFunctions.ts */
    earnedGold:0,
    spentGold: 0,
    addedLife: 0,
    reusableCards:[
        Rest0Breather,
        // 4 oriented around staying in the back and supporting as an archer/outdoors + healing/spell
        ArcheryArrow(1),
        FireWizardryFireDart(1),
        HealingTouch(1),
        OutdoorsSkill(1),
    ],
    defenseCards: [],
    discardableCards: [HealingPotion(1)],
    miscItems: "",
    supporters: ""
  },{
    playerName:"Noah",
    characterName:"Bronzo",
    role: "Shapechanging Brain In A Jar",
    earnedXP:12   /*6/30/2024*/+1 /*8/5/2024*/ +1 /*9/22/2024*/+1 /*10/20/2024*/+2,
    earnedGold:25 /*6/30/2024*/+4 /*8/5/2024*/ +4 /*9/22/2024*/+5 /*10/20/2024*/+5,
    spentGold: 0,
    addedLife: 6,
    reusableCards:[
        /*Support*/ Rest0Breather,
        /*Skills */ DisguiseSkill(4),
        /*Attacks*/ IceWizardryIceDart(4),
            cardAddEnhancements(TentacleAttack(4),[TentacleSpikes(1)]),
        /*Enhance*/ DustDevilsVeil1,
    ],
    defenseCards: [],
    discardableCards: [],
    miscItems: "Small luminescent gemstone",
    supporters: TsuiLetourneauSupporters
},{
  playerName: "Noah",
  characterName: "I am real human",
  role: "Skeletal Warrior",
  earnedXP: 12+1+1+1+2, // same as bronzo
  earnedGold: 25, // same as bronzo
  spentGold: 0,
  addedLife: 6,
  reusableCards:[
      /*Support*/ Rest0Breather,
      /*Skills */ InfluenceSkill(4), PhysicalSkill(2),
      /*Attacks*/ BarbaricAxeSwing(4),
      /*Enhance*/ AthleticRun(4),
  ],
  defenseCards: [SkeletalArmor(1)],
  discardableCards: [],
  miscItems: "Skeletal Horse",
  supporters: TsuiLetourneauSupporters
},{
    playerName:"Carl",
    characterName:"Carfaxx Abbey",
    role: "Psionic Monk",        
    earnedXP:12 /*6/30/2024*/+1 /*9/22/2024*/+1,
    earnedGold:25+4,
    spentGold: 0,
    addedLife: 0,
    reusableCards:[
        /*Support*/ConstructBarricade(3), AthleticRun(3), Rest0Breather,
        /*Skills */InfluenceSkill(1),PhysicalSkill(1),StealthSkill(1),
        /*Attacks*/FightingOneTwoPunch(3),TelepathMindControl(2),
    ],
    defenseCards: [],
    discardableCards: [],
    miscItems: "Horse, unidentified \"Disintegration Marble\", unidentified color-shifting sphere",
    supporters: TsuiLetourneauSupporters
},{
    playerName: "Brandon",
    characterName: "Rarrr",
    role: "Barbaric Dragonborn",
    earnedXP:12       /*6/30/2024*/+1 /*9/22/2024*/ +1,
    earnedGold: 25    /*6/30/2024*/+4 /*9/22/2024*/ +4,
    spentGold: 0,
    addedLife: 4,
    reusableCards: [
        /*Support*/ Rest0Breather,
        /*Skills */ SensesSkill(3),
        /*Attacks*/ DragonbornFirebreath(3), DestructExplode(2),
                    cardAddEnhancements(BarbaricSwordSlice(4),[Sword(4)]),
    ],
    defenseCards: [],
    discardableCards: [],
    miscItems: "Horse",
    supporters: TsuiLetourneauSupporters
},{
    playerName: "Bradley",
    characterName: "Viking Guy",
    role: "Viking",
    earnedXP: 11        /*8/5/2024*/ +1 /*10/20/2024*/+2,
    earnedGold: 17+4    /*8/5/2024*/ +4 /*10/20/2024*/+5,
    spentGold: 0,
    addedLife: /*8/5/2024*/2,
    reusableCards: [
        /*Support*/ Rest0Breather,
        /*Skills */ PhysicalSkill(2), StealthSkill(2), /*8/5/2024*/DisguiseSkill(2),
        /*Attacks*/ DestructExplode(2), SneakyKnifeThrow(4),
    ],
    defenseCards: [/*Defense_Stealth3_SneakyKnifeThrow3*/],
    discardableCards: [],
    miscItems: "Horse",
    supporters: TsuiLetourneauSupporters,
  },{
    playerName: "Jason",
    characterName: "Wolfgang", // full name Wolfgang Von Expo
    role: "Mystic Healer",
    earnedXP: 11        /*8/5/2024*/ +1 /*10/20/2024*/+2,
    earnedGold: 17+4    /*8/5/2024*/ +4 /*10/20/2024*/+5,
    spentGold: 5/*bought boat*/,
    addedLife: 0,
    reusableCards: [
        /*Support*/ Rest0Breather, HealingTouch(3),
        /*Skills */ StealthSkill(2), OutdoorsSkill(2), /*8/5/2024*/InfluenceSkill(1),
        /*Attacks*/ TelepathMindControl(4), FireWizardryFireDart(2),
    ],
    defenseCards: [],
    discardableCards: [],
    miscItems: "Horse, Boat on the Kingsport Docks",
    supporters: TsuiLetourneauSupporters
  }
] as PlayerCharacterInMemoryType[];