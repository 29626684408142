import { JSONSchemaType } from "ajv";
import { ExtensionJSONFormsObject, ExtensionNoteTemplateType } from "../ExtensionsFramework/ExtensionsList";


export type SaveNoteType = ExtensionJSONFormsObject & {
    Complete?: string[],
    Select?: string[],
};

export const SaveNoteSchema:JSONSchemaType<SaveNoteType> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},
        Complete: {
            type: "array",
            items: {type: "string"},
            nullable: true,
        },
        Select: {
            type: "array",
            items: {type: "string"},
            nullable: true,
        },
    },
    required: [],
};

export const Save_NoteType:ExtensionNoteTemplateType<SaveNoteType> = {
    name: "Save",
    emoji: "💾",
    description: "A note that contains the current relevant global context. It also defines which extensions to use.",
    template_doc_ids: ["832332c1-47b3-45f1-905e-ce32d434b563"], // empty template
    schema: SaveNoteSchema,
};

export const HowTo_NoteType:ExtensionNoteTemplateType<any> = {
    name: "How To",
    emoji: "📖",
    template_doc_ids: ["4fad9983-8bf6-4951-84ba-4591fb8c5859"], // empty template
    description: "A prompt optimized for the LLM (but can also be read by people) that explains how to handle a task or situation.",
};

export const DefaultNoteTemplateTypes = [
    Save_NoteType,
    HowTo_NoteType,
] as ExtensionNoteTemplateType<any>[];
