import { JSONSchemaType } from "ajv";
import { ExtensionJSONFormsObject } from "../../ExtensionsFramework/ExtensionsList";
import { Layout, UISchemaElement } from "@jsonforms/core";

export type EnemyAction = {
    aud: string
    viz: string
    cmd: string
};
export type EnemyActionArray = {Comment:string,action:EnemyAction[]}[];

const EnemyActionSchema:JSONSchemaType<EnemyAction> = {
    type: "object",
    properties: {
        viz: {type: "string", nullable: false, description: "Shortened reference using emojis. The first action in each array is usually a move. A move 2 looks like: 🦶🦶. The second is usually an attack. For an adjacent attack use: 📍🎯, for an attack that can be executed two squares away use: 📍⬛🎯",},
        aud: {type: "string", nullable: false, description: "Descriptive text to read aloud"},
        cmd: {type: "string", nullable: false, description: "Directions to GM",}
    },
    required: []
};
const EnemyActionArraySchema:JSONSchemaType<EnemyActionArray> = {
    type: "array",
    items: {
        type: "object",
        properties: {
            Comment: {type: "string", nullable: false, description: "Optional comment shown only the editor when this action is collapsed"},
            action: {
                type: "array",
                items: EnemyActionSchema,
                nullable: false
            }
        },
        required: []
    },
    nullable: false
};

export type Enemy = ExtensionJSONFormsObject & {
    // Inherited:
    // name: string,
    // id: string,
    // version: number,

    defaultDefense: string,
    maxHpLevel: number,
    damageLevel: number,

    actionsByRoundNum: EnemyActionArray,
    randomActions: EnemyActionArray,
    lowHPActions: EnemyActionArray
};

/************************************
 * Enemy UI Schema
 * 
 * We double-quote property keys so we can copy/paste into https://jsonforms-editor.netlify.app/ and edit there.
 * Do NOT use a second tab ("Category") that has content in it. If you want you could have a non-functional second tab (e.g. "Help"). But tabs cause bugs in editing -- any edits on the second and beyond tab cause the editor to flicker back to the first tab due to the version number incrementing and the editor itself being unable to handle that on the second tab.
 */
export const Enemy_JsonFormsUISchema = {
  "type": "VerticalLayout",
  "elements": [
    {
      "type": "Control",
      "scope": "#/properties/actionsByRoundNum",
      "label": "Offensive actions by round",
      "options": {
        "detail": {
          "type": "VerticalLayout",
          "elements": [
            {
              "type": "Control",
              "scope": "#/properties/Comment"
            },
            {
              "type": "Control",
              "scope": "#/properties/action",
              "options": {
                "detail": {
                  "type": "VerticalLayout",
                  "elements": [
                    {
                      "type": "Control",
                      "scope": "#/properties/viz"
                    },
                    {
                      "type": "Control",
                      "scope": "#/properties/cmd"
                    },
                    {
                      "type": "Control",
                      "scope": "#/properties/aud"
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    },
    {
      "type": "Control",
      "scope": "#/properties/randomActions",
      "label": "Offensive actions to choose randomly from",
      "options": {
        "detail": {
          "type": "VerticalLayout",
          "elements": [
            {
              "type": "Control",
              "scope": "#/properties/Comment"
            },
            {
              "type": "Control",
              "scope": "#/properties/action",
              "options": {
                "detail": {
                  "type": "VerticalLayout",
                  "elements": [
                    {
                      "type": "Control",
                      "scope": "#/properties/viz"
                    },
                    {
                      "type": "Control",
                      "scope": "#/properties/cmd"
                    },
                    {
                      "type": "Control",
                      "scope": "#/properties/aud"
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    },
    {
      "type": "Control",
      "scope": "#/properties/lowHPActions",
      "label": "Offensive actions when low HP",
      "options": {
        "detail": {
          "type": "VerticalLayout",
          "elements": [
            {
              "type": "Control",
              "scope": "#/properties/Comment"
            },
            {
              "type": "Control",
              "scope": "#/properties/action",
              "options": {
                "detail": {
                  "type": "VerticalLayout",
                  "elements": [
                    {
                      "type": "Control",
                      "scope": "#/properties/viz"
                    },
                    {
                      "type": "Control",
                      "scope": "#/properties/cmd"
                    },
                    {
                      "type": "Control",
                      "scope": "#/properties/aud"
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    },
    {
      "type": "Label",
      "text": "Defense"
    },
    {
      "type": "Control",
      "scope": "#/properties/defaultDefense"
    },
    {
      "type": "Label",
      "text": "Level"
    },
    {
      "type": "Control",
      "scope": "#/properties/maxHpLevel"
    },
    {
      "type": "Control",
      "scope": "#/properties/damageLevel"
    }
  ]
};

export const DefaultEnemy:Enemy = {
    name: "",
    id: "",
    version: 0,
    defaultDefense: "",
    maxHpLevel: 1,
    damageLevel: 1,
    actionsByRoundNum: [],
    randomActions: [],
    lowHPActions: []
};

export const EnemySchema:JSONSchemaType<Enemy> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},
        defaultDefense: {type: "string", nullable: false, description: "Optional description of defense, shown during players turn"},
        maxHpLevel: {type: "number", nullable: false, description: "Relative to other enemies"},
        damageLevel: {type: "number", nullable: false, description: "Relative to other enemies"},
        actionsByRoundNum: {...EnemyActionArraySchema, description: "Actions for the first few rounds, in order. Optional"},
        randomActions: {...EnemyActionArraySchema, description: "When none of the other action types apply, chooses randomly from these"},
        lowHPActions: {...EnemyActionArraySchema, description: "When the enemy is low on HP, chooses randomly from these. Optional"},
    },
    required: [],
};

// Uncomment to get schema in console so it can be pasted into the JSONForms editor at https://jsonforms-editor.netlify.app/
// console.log("EnemySchema", EnemySchema);
// console.log("Enemy_JsonFormsUISchema",Enemy_JsonFormsUISchema);