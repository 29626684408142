import { Button } from "antd";
import { ReactNode } from "react";

export const SIDER_FONT_COLOR = "gray";

export function siderItemPaddingLeft(siderCollapsed:boolean) {
    return siderCollapsed?"10px":"24px";
}

export function SiderMenuButton({labelIcon, labelText, onClick, siderCollapsed}: {labelIcon:ReactNode, labelText:string, onClick: ()=>void,siderCollapsed:boolean}) {
    // TODO Surely we could do better than this funky class styling, perhaps by using the menu item class?

    return <Button className=".ant-menu-item .antmenu-item-icon+span" type="text" onClick={onClick} style={{alignItems:"center", color:SIDER_FONT_COLOR,paddingLeft:siderItemPaddingLeft(siderCollapsed), width: "100%",paddingRight:siderCollapsed?"0px":"105px"}}>
        {labelIcon} {!siderCollapsed && labelText}
    </Button>;
}