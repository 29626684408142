import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SelectedJSONFormsContext } from "../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { useDeleteNoteCallback } from "../../Notes/Data/NoteDBHooks";
import { useQuery, getQuery } from "../Utilities/URLQueryParams";


export function useDeleteSelectedNotesAction() {
  // For operations on a single note, we get doc_id:
  const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
  const note = selectedJsonFormsContext.note;
  const doc_id = note?.id;

  const deleteNoteCallback = useDeleteNoteCallback();
  const doc_name = note?.doc_name;
  // For deleting notes:
  const query = useQuery();
  const navigate = useNavigate();

  // If one note is selected, we can delete it:
  const selectedNoteIDs: string[] = query.getAll("notes");

  async function onDeleteNoteClicked() {
    // Single document delete:
    const freshQuery = getQuery();
    if (doc_id) {
      if (window.confirm("Are you sure you want to delete " + doc_name + "?")) {
        deleteNoteCallback(doc_id);
        // Make sure the note gets deleted from the search context:
        // notesContext.removeNote(doc_id);
        // Go back to a no selection page, preserve query      
        navigate("/?" + freshQuery.toString(), { replace: true });
      }
    } else if (selectedNoteIDs.length > 0) {
      if (window.confirm("Are you sure you want to delete " + selectedNoteIDs.length + " notes?")) {
        for (const doc_id of selectedNoteIDs) {
          deleteNoteCallback(doc_id);
          // Make sure the note gets deleted from the search context:
          // notesContext.removeNote(doc_id);
        }
        freshQuery.set("notes", "");
        navigate("/?" + freshQuery.toString(), { replace: true });
      }
    } else {
      console.error("We should not be able to reach this line!");
      debugger;
    }
  }

  return onDeleteNoteClicked;
}
