import { useContext, useMemo, useState } from 'react';

import { Alert, Button, Modal, Select, Spin } from 'antd';
import { QuestionCircleOutlined, UploadOutlined} from '@ant-design/icons';

import { useAllTypeNotes, useNote } from '../../Notes/Data/NoteDBHooks';
import { Note, PartialJSONFormsObject } from '../../Notes/Data/NoteType';

import SvelteJSONEditor from '../../JSONEditing/RawJSONEditing/SvelteJSONEditor';
import ValidationError from 'ajv/dist/runtime/validation_error';
import { createAjvValidator } from 'vanilla-jsoneditor';
import { debounce, isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import { getDuplicatesErrorMessage } from '../../JSONEditing/JSONSchemaBasedEditors/JSONSchemaHelpers';
import { NotesContext } from '../../Notes/Data/NotesContext';
import SelectNoteType from '../../Notes/SelectNoteType';
import {JSONFormsObjectDiff, uploadDiffs} from '../../Notes/Data/Actions/JSONFormsObject/DiffJsonFormsObject';
import { validateBulk } from '../../Notes/Data/Actions/ValidateNewNotes';
import { useJSONFormsSchema } from '../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONSchemaObject';
import CommandResult from './CommandResult';

const { Option } = Select;


export function useBulkUploadMenuItem() {
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);
  const bulkUploadMenuItem = {
    onClick: function(){setIsBulkUploadModalOpen(true);},
    icon: <UploadOutlined />,
    label: "Bulk upload",
    key: "BulkUpload",

  };
  const bulkUploadComponent = isBulkUploadModalOpen?<CommandResult closeNowFunc={function(){setIsBulkUploadModalOpen(false)}} key="BulkUpload" commandType="BulkUpload"/>:<></>;
  return {bulkUploadMenuItem, bulkUploadComponent};
}


function BulkUploadModelStep1Inner(
    {type, setErrorMessage, setInfoMessage, setObjArray, setValidatedDiffs, selectedTypeNote, isAsynchValidatingJSON, setIsAsynchValidatingJSON}:
    {type:string, type_note_id:string, setErrorMessage:any,
      setInfoMessage:any, setObjArray:any, setValidatedDiffs:any, selectedTypeNote:Note, isAsynchValidatingJSON:boolean, setIsAsynchValidatingJSON:any}) {
  const jfsDoc = useJSONFormsSchema(selectedTypeNote.id);
  const schema = jfsDoc?.['type-schema'];
  const [lastAsyncValidatedJSON, setLastAsyncValidatedJSON] = useState({});
  const firestore = useFirestore();

  // TODO this doesn't seem to be using the cache of notes. Not sure why.
  // As a result, it's much slower than it needs to be. We'll accept that for now.
  const asyncValidationDebounced = useMemo(()=>{
    async function asyncValidation(json:PartialJSONFormsObject[]) {
      // console.log("Asynch validation triggered...")
      // This takes a while -- so benefits from some lots of extra protections.
      // TODO this would go faster if we were accessing the local cache, if we know if everything's loaded...
      // But we don't really know if everything's loaded, so this is the future-proof technque, and must be implemented either way. The above would be an optimization.
      if (isAsynchValidatingJSON) {
        return;
      }
      if (isEqual(lastAsyncValidatedJSON,json)) {
        // don't revalidate.
        return;
      }
      setValidatedDiffs([]);
      setIsAsynchValidatingJSON(true);
      setLastAsyncValidatedJSON(json);
      // console.log("Starting checks!")
  
      setInfoMessage("..."); // already says it in the title.
      // TODO block submission here until checks are complete.
  
      const results = await validateBulk(firestore, json, setInfoMessage);
      setIsAsynchValidatingJSON(false);
      if (results.hasError) {
        setErrorMessage(results.message);
      } else {
        setInfoMessage(results.message);
        setIsAsynchValidatingJSON(false);
        if (!results.hasContent) {
          // Nothing to do though.
        } else {
          setValidatedDiffs(results.diffs);
        }
      }
    }  
    return debounce(asyncValidation,1000);
  },[]);


  return  <>{/* @ts-ignore */}
    <div className="my-svelte-editor-in-modal">
    <SvelteJSONEditor
      onChange={function onJSONChange(obj:any){
        // TODO could we skip this onChange and just do everything in validation below?
        // It would be more streamlined, but the function names wouldn't be quite right so it's
        // a tiny bit sketchy.
        // Validation: check if it's an array, check if the items match the schema
        let jsonObj;
        if (obj.text) {
          // Error message should be included in the box I think.
          try {
            jsonObj = JSON.parse(obj.text);
          } catch {
            // This error is shown by the editor, no need for a duplicate.
            setErrorMessage("");
            setObjArray(null)
            setInfoMessage("");
            return;
          }
        } else {
          jsonObj=obj.json;
          setObjArray(jsonObj);
        }
        // TODO check if this works using the validation below instead.
        return;
      }}
      validator={function onValidation (json: any): ValidationError[]{
        // We can do some validation, even without a schema.
        if (!json) {
          return []; // hopefully alreayd displayed?
        }
        if (!Array.isArray(json)) {
          // Well, we can see if it's a single valid object later...
          json = [json];
          // setErrorMessage("JSON must be an array.");
          // setInfoMessage("");
          // return [];
        }
        if (json.length===0) {
          // Nothing to save
          setErrorMessage("Add at least one item.");
          setInfoMessage("");
          return [];
        }
        if (!schema) {
          // No additional validation we can do right now
          setErrorMessage("Before using this dialog, you need to create an object schema in the type '"+type+"'.");
          return [];
        }
        const validator = createAjvValidator({schema:JSON.parse(schema)});
        let errors = [] as ValidationError[];
        for (let i=0; i<json.length; i++) {
          const item = json[i];
          // TODO the error message doesn't give a hint as to which item is broken.
          // We need to add that in, perhaps in our own error message.
          
          //@ts-ignore
          errors = errors.concat(validator(item));
          if (errors.length>0) {
            // just report this first one.
            setErrorMessage("The error above is for item index="+(i)+".");
            return errors;
          }
        }
        // Confirmed from the above:
        json = json as PartialJSONFormsObject[];
        const duplicatesMessage=getDuplicatesErrorMessage(json.map(function(item:PartialJSONFormsObject){return item.name}),"Before you can upload, you must fix duplicate names");
        if (duplicatesMessage.length>0) {
          setErrorMessage(duplicatesMessage);
          return [];
        }
        setErrorMessage(""); // we checked, there's nothing wrong.
        asyncValidationDebounced(json);
        return errors;
      }}
    />
    {/* @ts-ignore */}
    </div></>

}



function SelectTreeLocation({selected_note_id, selectedTypeNote,
      type, parent, setParent}:
      {selectedTypeNote:Note, selected_note_id:string, type:string, parent:string, setParent:any}) {
  // Get the name of the selected note:
  const note = useNote(selected_note_id);
  const selected_note_name = note?.doc_name;
  const type_id = selectedTypeNote.id;
  

  
  return <>
    {note && selectedTypeNote && /*wait until the note names are loaded until we show the choice*/
     <Select placeholder="" style={{ minWidth: '200px' }}
            value={parent}
            onChange={function(value:string){
                if (type===value) {
                    // console.log("Got event, but no change");
                    return;
                }
                setParent(value);
            }} >
            <Option key={type_id} value={type_id}>{type}</Option>
            <Option key={selected_note_id} value={selected_note_id}>{selected_note_name}</Option>
          </Select>}
  </>
}

export default function BulkUploadModal({isModalOpen, setIsModalOpen}:{isModalOpen:boolean, setIsModalOpen:any}) {
  const [type,setType] = useState("");
  const [selectedParentId, setSelectedParentId] = useState("");
  const typeNotes = useAllTypeNotes();
  const selectedTypeNote = typeNotes && typeNotes.filter(function(note:Note){return note.doc_name===type})[0];
  const type_id = selectedTypeNote?.id;
  const notesContext = useContext(NotesContext);

  const [objArray, setObjArray] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [validatedDiffs, setValidatedDiffs] = useState([] as JSONFormsObjectDiff[]);
  const [step, setStep] = useState("Setup");
  const [uploadMessage, setUploadMessage] = useState("Uploading...");
  const [isAsynchValidatingJSON, setIsAsynchValidatingJSON] = useState(false);
  const firestore = useFirestore();


  // For asking where to parent the notes:
  //@ts-ignore
  const { doc_id }:{doc_id:string} = useParams();

  async function handleUploadClicked() {
    // Time for a new modal:
    setStep("Uploading");
    function setIntermediateProgress(soFar:number,total:number) {
      setUploadMessage("Uploaded "+(soFar)+"/"+total);
    }
    function setUploadDone() {
        setIsModalOpen(false);
        // Reset any intermediate things so it can be re-opened in the initial state:
        setStep("Setup");
        // TODO need to empty out the object too -- but the json editor isn't controlled yet so this won't have an effect:
        setObjArray(null);    
    }
    uploadDiffs(firestore, selectedParentId || type_id, validatedDiffs, type, notesContext, setIntermediateProgress,setUploadDone);
  };

  function handleCancel() {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal title="Bulk Upload" open={isModalOpen && step==="Setup"} onOk={handleUploadClicked} onCancel={handleCancel} width="90vw" style={{ top: 20 }}
      footer={[
        <Button
        key="link"
        type="link"
        href="https://docs.google.com/document/d/1tC9-O242zeWVzZoXZiHP6f75U565Pykp5oPIas3276c/preview?rm=demo"
        target="_blank"
        //@ts-ignore
        icon={<QuestionCircleOutlined />}
        >Help</Button>,
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleUploadClicked}
          disabled={type==="" || errorMessage!=="" || objArray===null || validatedDiffs.length===0}>
          Upload
        </Button>,
      ]}
      >
        {/* @ts-ignore */}
        Type to upload:&nbsp; &nbsp;
        <SelectNoteType onChange={setType} defaultValue={type} />
        {type && doc_id && type_id!==doc_id && /*we don't offer a choice unless there is a choice:*/
          <>&nbsp; &nbsp; &nbsp; Place in the tree under: 
            <SelectTreeLocation selectedTypeNote={selectedTypeNote} selected_note_id={doc_id} type={type} parent={selectedParentId} setParent={setSelectedParentId}/>
          </>
        }
        {/* @ts-ignore */}
        <br/><br/>
        {/* @ts-ignore */}
        {type && selectedTypeNote && <BulkUploadModelStep1Inner setErrorMessage={setErrorMessage} setInfoMessage={setInfoMessage}
          setObjArray={setObjArray} type={type}
          setValidatedDiffs={setValidatedDiffs} selectedTypeNote={selectedTypeNote}
          isAsynchValidatingJSON={isAsynchValidatingJSON} setIsAsynchValidatingJSON={setIsAsynchValidatingJSON}
          />}
        {errorMessage && <Alert
            message="Error"
            description={errorMessage}
            type="error"
            showIcon
            closable
            />}
          {isAsynchValidatingJSON && <Alert
            message="Checking for changes"
            description={infoMessage}
            type="info"
            icon={<Spin/>}
            showIcon
            closable
            />}
          {!isAsynchValidatingJSON && infoMessage && <Alert
            message="What will be changed"
            description={infoMessage}
            type="info"
            showIcon
            closable
            />}
      </Modal>
      <Modal title="Uploading in bulk..." open={isModalOpen && step==="Uploading"} style={{ top: 20 }} closable={false}
      footer={[]}
      >
        {/* @ts-ignore */}
        <Spin /> &nbsp;&nbsp;&nbsp;{uploadMessage}
      </Modal>
    </>
  );
};