import { ReusableCard, XPAtLeast1 } from "./GameCardTypes";

import SensesImg from "./CardImages/SD/Skill_Senses.png";
import PhysicalImg from "./CardImages/SD/Skill_Physical.png";
import DisguiseImg from "./CardImages/SD/Skill_Disguise.jpg";
import InfluenceImg from "./CardImages/SD/Skill_Influence.png";
import StealthImg from "./CardImages/SD/Skill_Stealth.png";
import OutdoorsImg from "./CardImages/SD/Skill_Outdoors.png";
import { PRODUCE_LEVEL_CARDS, produceLevelCards } from "./ReusableCards";
import { get } from "lodash";


function getSkillRollString(lowercase:boolean=false) {
    const str = "Roll **%xp+1%** skill dice to ";
    // at level 1, consider adding "check whether you can ";
    // const str = "Roll a d20 and add **+%xp%** to ";

    if (lowercase) return str.toLowerCase();
    return str;
}

export function SensesSkill(level:XPAtLeast1) {
    const examples = " E.g. perceive, spot hidden, search, notice danger, traps, clues, faint sounds, something out of the ordinary"
    let commandString = getSkillRollString()+"notice something in the environment by using the 5 senses and ability to interpret them:  sight, hearing, smell, taste, or touch.";
    if (level<3)
        commandString += examples;
    return {
        name: "Senses",
        discipline: "Skill",
        actionType: "skill",
        image: SensesImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 10,    
        commandString,
    } as ReusableCard;
}
export function PhysicalSkill(level:XPAtLeast1) {
    const examples = " E.g. strength, dexterity, athletics, strength, jump, climb, swim, run, lift, push, pull, catch, balance, dodge, tumble, grapple, throw";
    let commandString = "⚡: "+getSkillRollString()+"perform a feat of physical prowess, coordination, or strength.";
    if (level<3)
        commandString += examples;
    return {
        name: "Physical",
        discipline: "Skill",
        actionType: "skill",
        image: PhysicalImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 0,
        commandString,
    } as ReusableCard;
}

export function DisguiseSkill(level:XPAtLeast1):ReusableCard {
    let commandString = "⚡⚡ **Sustained**: Change your appearance to look like someone or something else.";
    if (level<3)
        commandString += " This energy is **sustained**: it cannot regenerate until you change your appearance back to normal.";
    commandString += " Each time your appearance might decieve an observer, "+getSkillRollString(true)+"check whether they believe the change.";
    return {
        name: "Disguise",
        discipline: "Skill",
        actionType: "skill",
        image: DisguiseImg,
        xpCost: level,
        goldCost: level*5,
        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 5,
        commandString,
    } as ReusableCard;
};
export function InfluenceSkill(level:XPAtLeast1):ReusableCard {
    const examples = " E.g. convince, sell, negotiate, intimidate, diplomacy, politics";
    // let commandString = "⚡: "+getSkillRollString()+"check whether you can persuade someone to do something they don't already want to do.";
    let commandString = getSkillRollString()+ "persuade someone to do something they don't already want to do.";    
    if (level<3)
        commandString += examples;
    return {
        name: "Influence",
        discipline: "Skill",
        actionType: "skill",
        image: InfluenceImg,
        xpCost: level,
        goldCost: 0,
        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 5,
        commandString,
    } as ReusableCard;
}
export function StealthSkill(level:XPAtLeast1):ReusableCard {
    const examples = " E.g. sneak, hide, avoid detection, blend in, do an action without being noticed";
    // let commandString = "⚡ **Sustained**: Perform an action stealthily. This energy is **sustained**: it cannot regenerate until you stop using stealth. Each time you could be detected by an observer, "+getSkillRollString(true)+"check whether they notice.";
    let commandString = "Perform an action stealthily. Each time you could be detected by an observer, "+getSkillRollString(true)+"check whether they notice.";
    if (level<3)
        commandString += examples;
    return {
        name: "Stealth",
        discipline: "Skill",
        actionType: "skill",
        image: StealthImg,

        xpCost: level,
        goldCost: 0,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 0,

        commandString,
    } as ReusableCard;
}
export function OutdoorsSkill(level:XPAtLeast1):ReusableCard {
    const examples = " E.g. track, hunt, forage, find water, find shelter, start a fire, navigate with or without a map, make camp, fish, read natural signs";
    // let commandString = "⚡: "+getSkillRollString()+"check whether you successfully navigate or interact with outdoor environments.";
    let commandString = getSkillRollString()+"interact with outdoor environments.";
    if (level<3)
        commandString += examples;
    return {
        name: "Outdoors",
        discipline: "Skill",
        actionType: "skill",
        image: OutdoorsImg,

        xpCost: level,
        goldCost: 0,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 0,

        commandString,
    } as ReusableCard;
}    
export function LuckSkill(level:XPAtLeast1):ReusableCard {    
    const examples = "E.g. finding something you need in a convenient location, fate, background knowledge relevant to this situation";
    let commandString = getSkillRollString()+"determine your good fortune in an outcome that happens beyond your control.";
    if (level<3)
        commandString += examples;
    return {
        name: "Luck",
        discipline: "Skill",
        actionType: "skill",
        // image: LuckImg,

        xpCost: level,
        goldCost: 0,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 0,

        commandString,
    } as ReusableCard;
}

export const AllSkillCardFunctions = [
    SensesSkill, PhysicalSkill, DisguiseSkill, InfluenceSkill, StealthSkill, OutdoorsSkill, LuckSkill
];

export const AllSkillCards: ReusableCard[] = [];
AllSkillCardFunctions.forEach(skillFunc => {
    AllSkillCards.push(...produceLevelCards<ReusableCard>(skillFunc,PRODUCE_LEVEL_CARDS));
});