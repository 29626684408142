import { ActionCardEnhancement, ReusableCard, XPAtLeast1 } from "./GameCardTypes";

export const OldLongbow = {
    name: "Old Longbow",
    applicableToDisciplines: ["Archery"],
    maxRange: 1,
    moveSpaces: 0,
    minLevel: 1,
    goldCost: 10,
} as ActionCardEnhancement;

export const DecentArrows = {
    name: "Decent Arrows",
    applicableToDisciplines: ["Archery"],
    moveSpaces: 0,
    minLevel: 1,
    dicePerAffectedSpace: {plus:1,numDice:0,diceType:0},
    goldCost: 10,
} as ActionCardEnhancement;

export const Clogs = {
    name: "Clogs (🦶+1)",
    // applicable to all disciplines
    moveSpaces: 1,
    minLevel: 1,
    goldCost: 10,
} as ActionCardEnhancement;

export const BoxingGloves = {
    name: "Boxing Gloves",
    applicableToDisciplines: ["Fighting"],
    moveSpaces: 0,
    minLevel: 1,
    goldCost: 10,
    dicePerAffectedSpace: {plus:1,numDice:0,diceType:0},
} as ActionCardEnhancement;

export function TentacleSpikes(level:XPAtLeast1):ActionCardEnhancement {
    let name = "Wooden Tentacle Spikes";
    if (level===2)
        name = "Rusty Tentacle Spikes";
    else if (level===3)
        name = "Iron Tentacle Spikes";
    else if (level===4)
        name = "Steel Tentacle Spikes";
    else if (level===5)
        name = "Mithril Tentacle Spikes";
    else {
        name = "Enchanted Tentacle Spikes";
        if (level>6)
            name+= "+"+(level-6);
    }
    const goldCost = level*5;
    return {
        name,
        applicableToDisciplines: ["Brain In A Jar"],
        moveSpaces: 0,
        minLevel: level,
        goldCost,
        dicePerAffectedSpace: {plus:level,numDice:0,diceType:0},
    } as ActionCardEnhancement;
}

export function Sword(level:XPAtLeast1):ActionCardEnhancement {
    let name = "Wooden Sword";
    if (level===2)
        name = "Rusty Sword";
    else if (level===3)
        name = "Iron Sword";
    else if (level===4)
        name = "Steel Sword";
    else if (level===5)
        name = "Mithril Sword";
    else {
        name = "Enchanted Sword";
        if (level>6)
            name+= "+"+(level-6);
    }
    const goldCost = level*5;

    return {
        name,
        applicableToDisciplines: ["Barbaric"],
        moveSpaces: 0,
        minLevel: level,
        goldCost,
        dicePerAffectedSpace: {plus:level,numDice:0,diceType:0},
    } as ActionCardEnhancement;
}


export function cardAddEnhancements(card:ReusableCard,enhancements:ActionCardEnhancement[]):ReusableCard {
    return {
        ...card,
        // name: card.name+" & "+enhancements.length,
        name: card.name+" +",
        enhancements: enhancements,
    };
}

export const AllEnhancements = [OldLongbow, DecentArrows, Clogs, BoxingGloves];
